import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

const API = `${environment.api}/api`;

@Injectable({
  providedIn: 'root'
})
export class PartenairesService {

  constructor(private http: HttpClient) { }

  getPartenaires(language: string) {
    let headers = new HttpHeaders().set('Accept-Language', language);
    return this.http.get(API +'/partners', {headers: headers});
  }
}
