<section class="profil-section">


    <div class="profil-container">
      <div class="nav-side-profil-wrapper">
        <span><i class="fa fa-user-circle"></i>{{'ACCOUNT.MY-ACCOUNT' | translate}}</span>
        <a [ngClass]="{'active' : profil}" (click)="changeProfil('profil')">{{'ACCOUNT.MY-PROFILE' | translate}}</a>
        <a [ngClass]="{'active' : commande}" (click)="changeProfil('commande')">{{'ACCOUNT.MY-ORDERS' | translate}}</a>
      </div>

      <div class="profil-informations">
        <app-profil-informations *ngIf="profil"></app-profil-informations>
        <app-profil-commandes *ngIf="!profil"></app-profil-commandes>
      </div>
    </div>
</section>
