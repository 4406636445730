import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  @Input('image') image: any;
  @Input('titre') titre: any;
  @Input('sousTitre') sousTitre: any;

  //si on est sur un produit précis, une actualités etc. ->
  // -> On récupère le nom et on l'affiche dans le span pour qu'il soit bien visible
  @Input('focus') focus: any;

  constructor() { }

  ngOnInit(): void {
  }

}
