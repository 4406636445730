import { Component, OnInit } from '@angular/core';
import {PressbookService} from "../../_services/pressbook.service";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

const S3 = `${environment.s3}`;

@Component({
  selector: 'app-pressbook',
  templateUrl: './pressbook.component.html',
  styleUrls: ['./pressbook.component.scss']
})
export class PressbookComponent implements OnInit {

  pressbooks: any = [];
  S3 = S3;

  constructor(private pressbookService: PressbookService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.pressbooks = [];
      this.getPressbooks();
    })
    this.getPressbooks()
  }

  getPressbooks() {
    this.pressbookService.getPressbook().subscribe(
      (data) => {
        this.pressbooks = data;
      }
    );
  }

}
