<app-intro [image]="'/assets/images/bandeau-titre/bandeau-actualites.png'" [titre]="'FOOTER.NAVIGATION.SEARCH'|translate"
           [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>

<div class="ng-autocomplete" id="searchContainer">

  <form [formGroup]="searchForm">
    <mat-form-field appearance="outline" class="example-form-field full-width">
      <mat-label>{{'FOOTER.NAVIGATION.SEARCH'|translate}}</mat-label>
      <input formControlName="search" matInput type="text" [(ngModel)]="value">
      <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </form>
</div>


<div class="container-produits">
  <div class="align-items-start">
    <div class="mb-3 row ">
      <div class="col-lg-3" *ngFor="let p of products | searchFilter: searchForm.value.search | paginate: { itemsPerPage: 8, currentPage: p } ">
        <app-product [product]="p"></app-product>
      </div>
      <pagination-controls (pageChange)="p = $event" previousLabel="" nextLabel="" class="float-right"></pagination-controls>
    </div>
  </div>
</div>
