import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-qui-suis-je',
  templateUrl: './qui-suis-je.component.html',
  styleUrls: ['./qui-suis-je.component.scss']
})
export class QuiSuisJeComponent implements OnInit {

  lang = this.translate.currentLang;
  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((event) => {
      this.lang = event.lang;
    })
  }

  ngOnInit(): void {

  }
}
