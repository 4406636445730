import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {CartItem} from "../_models/cart-item";
import {Product} from "../_models/product";

import {map} from "rxjs/operators";
import {environment} from 'src/environments/environment';
import {TokenStorageService} from "./token-storage.service";
import {TranslateService} from "@ngx-translate/core";

const API = `${environment.api}/api/account/cart`;
const API_COMMANDE = `${environment.api}/api/account/orders`;
const API_ORDERS = `${environment.api}/api/account/cart`;
const ENV = `${environment.api}/api/account/orders/`;

@Injectable({
  providedIn: 'root'
})
export class CartService {

  roles: string[] = [];

  constructor(private http: HttpClient, private token: TokenStorageService, private translate: TranslateService) {
  }

  ngOnInit() {
  }

  getCartItems(token: any): Observable<CartItem[]> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Accept-Language': this.translate.currentLang
    });


    return this.http.get<CartItem[]>(
      API,
      {
        headers: reqHeader
      }
    ).pipe(
      map((result: any[]) => {
        let cartItems: CartItem[] = [];
        var o = 0;

        // @ts-ignore
        for (let ite of result.cartItems) {
          let productExists = false
          for (let i in cartItems) {
            if (cartItems[i].productId === ite.product.id) {
              cartItems[i].qty++
              productExists = true
              break;
            }
          }

          if (!productExists) {
            cartItems.push(new CartItem(o, ite.product.id, ite.product.label, ite.product.price, ite.quantity, ite.product.thumbnails[0], ite.product.quantityInStock));
          }
          o++;
        }
        return cartItems;
      })
    );
  }

  addProductToCart(product: any, token: any, qty: number): Observable<any> {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.put(API + "/set-product", {
      product: product.productId,
      quantity: qty
    }, {headers: reqHeader}
    )
  }


  removeFromCart(product: any, token: any) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    // @ts-ignore
    return this.http.put(API + '/remove-product', {
      product: product.productId,
    }, {headers: reqHeader});
  }

  getCommandes(token: string | null) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });

    return this.http.get(API_COMMANDE, {headers: reqHeader});
  }

  validateOrder() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token.getToken()
    });

    return this.http.post(API_ORDERS + '/purchase', {},{headers: reqHeader});
  }

  getFacture(id : any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token.getToken()
    });

    return this.http.get(ENV + id + '/download', {headers, responseType: 'blob'}).subscribe((res: Blob) => {
      const blob = new Blob([res], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
