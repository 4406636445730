<div class="qty-desc">
  <div class="num-block skin-2">
    <div class="num-in">
      <span (click)="decrement()" class="minus dis"></span>
      <input #customQty
             type="text"
             class="in-num"
             [value]="value"
             [max]="max"
             [min]="min"
             [step]="step"
      >
      <span (click)="increment()" class="plus"></span>
    </div>
  </div>
</div>
