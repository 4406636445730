<div class="container p-3" *ngIf="lang === 'fr'">
  <h1>Conditions générales de vente</h1>
  <p class="cgv-text p-3">
    <span>1. Définition</span>
    <br/>Sera désigné “Client” dans les présentes conditions générales de vente (les “CGV”) toute personne souhaitant acquérir un ou des produits proposés à la vente (le(s) “Produit(s)”)
    sur le site Internet www.maisonbleulin.com (le “Site Internet”) en respectant les Conditions Générales de Vente. Chaque commande effectuée par un Client dans les conditions prévues par les présentes Conditions Générales sera ci-après dénommée une “Commande”.
    <br/>Les sociétés auxquelles MAISON BLEU LIN fait appel pour l’acheminement des produits chez le client sont ici dénommées “Transporteurs”.

    <br/><br/><span>2. Objet des Conditions Générales de vente</span>
    <br/>Les Conditions Générales de Vente (CGV) ont pour objet de définir les droits et obligations des parties dans le cadre de la vente sur le Site Internet des Produits proposés par MAISON BLEU LIN au Client.
    <br/>Le Client reconnaît avoir pris connaissance, au moment de la passation de Commande, des Conditions Générales de Vente énoncées sur cet écran. En ce sens, le Client reconnaît accepter sans réserve l’intégralité des dispositions prévues aux présentes dans les conditions ci-dessous.
    <br/>Ces Conditions Générales de Vente prévaudront sur toutes autres conditions figurant dans tout autre document, sauf dérogation préalable, expresse et écrite. Dans la mesure où le Client commande en ligne les Produits présentés sur le Site Internet, la signature électronique du Client sur la page de validation de la Commande,
    marque la conclusion du contrat entre le Client et MAISON BLEU LIN. Conformément à la loi du 13 mars 2000 sur la signature électronique et la loi sur la confiance pour l’économie numérique du 21 juin 2004,
    toute Commande validée par le Client par son “double clic” ou par son accord au téléphone constitue une acceptation irrévocable des présentes Conditions Générales de Vente par le Client,
    à l’instar d’une signature manuscrite.

    <br/><br/><span>3. Le Site Internet</span>
    <br/>Toute Commande validée par un Client sur le Site Internet implique l’acceptation préalable des présentes Conditions Générales de Vente. Le Site Internet est exploité par :
    <br/>MAISON BLEU LIN, entreprise individuelle, dont le siège social est situé 9 rue du petit fort, 22100 DINAN, immatriculée au RCS de St Malo sous le numéro 419 939 715 000 19
    <br/>TVA Non applicable, Article 293B du Code Général des Impôts.
    <br/>Le Site Internet est hébergé par la sociétéxxxx , société au capital de , dont le siège social est situé  xxxx, immatriculée au RCS xxx sous le numéro xxxxx

    www.maisonbleulin est un site de commerce électronique détenu et géré par Maison Bleu Lin. Le site internet est accessible à tous les utilisateurs du réseau internet.
    <br/>Maison Bleu Lin ne saurait être tenue responsable de tout dommage, qu'elle qu'en soit la nature, résultant d'une indisponibilité du site.

    <br/>Maison Bleu Lin ne peut garantir que le site sera exempt d'anomalies, erreurs ou bugs, ni que le site fonctionnera sans panne ni interruption.
    <br/>Elle peut à cet égard déterminer librement et à son entière discrétion toute période d'indisponibilité du site ou de son contenu.
    <br/>Maison Bleu Lin ne peut non plus être tenue responsable de problèmes de transmission de données, de connexion ou d'indisponibilité du réseau.

    <br/><br/>Maison Bleu Lin se réserve le droit de faire évoluer le Site Internet, pour des raisons techniques ou commerciales.
    Lorsque ces modifications n'altèrent pas les conditions de la fourniture des services, de manière substantielle ou négative,
    le client peut être informé des modifications intervenues, mais son acceptation n'est pas sollicitée.
    <br/><br/><span>4. Validité des Conditions Générales</span>
    MAISON BLEU LIN se réserve le droit de modifier ses Conditions Générales de Vente à tout moment et pour quelque raison que se soit. Toutefois, les Produits commandés sur le Site Internet par les Clients sont régis par les Conditions Générales de Vente en ligne à la date de la Commande et acceptées par le Client.

    <br/><br/><span>5. Durée du contrat</span>
    <br/>Les Conditions Générales de Vente sont conclues pour la durée nécessaire à la fourniture des Produits, jusqu’à l’extinction de leurs garanties.

    <br/><br/><span>6. Document probatoire</span>
    <br/>Les informations contractuelles au sujet de la Commande feront l’objet d’une confirmation par voie d’email.
    <br/>MAISON BLEU LIN recommande au Client de conserver son email de confirmation de Commande ou de l’imprimer.
    <br/>Cependant, pourront également constituer une preuve juridique de toute communication, toute Commande,
    et tout paiement intervenu entre les Parties, tous documents issus de l’archivage dans les systèmes informatiques de MAISON BLEU LIN.

    <br/><br/><span>7. Les Produits</span>
    <br/>Tout professionnel vendeur de biens doit, avant la conclusion du contrat, mettre le consommateur en mesure de connaître les caractéristiques essentielles du bien.
    <br/>Les biens proposés sur le site constituent une publicité et sont à ce titre soumis aux dispositions de l'article L 121-1 du code de la consommation.
    <br/>Les Produits proposés à la vente sont ceux décrits sur le site au jour de la consultation du Site par le client, dans la limite des stocks disponibles.

    <br/>Toutefois une erreur dans la mise à jour, qu'elle qu'en soit l'origine, n'engage pas la responsabilité de MAISON BLEU LIN.
    <br/>Maison Bleu Lin fera tout son possible pour proposer une solution alternative au Client dans ce cas précis, sans pouvoir le garantir.

    <br/>MAISON BLEU LIN s’efforce de présenter au mieux, les Produits, et les caractéristiques essentielles de ces derniers. Il est toutefois possible que des erreurs puissent figurer sur le site,
    ce que le Client reconnaît et accepte. De manière générale, les descriptions (photographies, textes, graphismes, informations et caractéristiques) illustrant les Produits présentés sont données à titre indicatif.
    <br/>En cas d’erreur de description (photographies, textes, graphismes, informations ou caractéristiques) sur un Produit, la responsabilité de MAISON BLEU LIN ne saurait être engagée.
    <br/>MAISON BLEU LIN s’engage à honorer les Commandes reçues dans la limite des stocks disponibles. A défaut de disponibilité du Produit, MAISON BLEU LIN s’engage à en informer au plus vite le Client et à lui proposer un Produit similaire,un remboursement ou bien un avoir à valoir sur l’ensemble des Produits non disponibles.

    <br/>Si le Client opte pour un remboursement, MAISON BLEU LIN s'efforcera de rembourser le Client dans un délai de trente (30) jours
    à compter de la date à laquelle MAISON BLEU LIN aura informé le Client de l’indisponibilité du Produit.

    <br/><br/><span>8. Prix des Produits</span>
    <br/>Les prix des Produits vendus sur le Site Internet sont indiqués en euros Hors Taxes (HT)TVA non applicable, art 293B du CGI Code Général des Impôts, hors participation aux frais d’expédition.
    et sont valables tant qu’ils sont présents sur la page descriptive du Produit figurant sur le Site Internet. Le prix indiqué sur les fiches produit ne comprend pas le transport :
    celui-ci varie selon le transporteur, la solution choisie (livraison à domicile ou en point-relais), le poids de la commande et la destination.
    <br/>Tous les prix sont donnés sous réserve d’erreur typographique manifeste. Si une erreur devait survenir MAISON BLEU LIN
    contactera le Client pour l’avertir de l’erreur effectuée et lui rappellera que la Commande sera facturée aux conditions corrigées.
    <br/>Cependant, en cas de refus des conditions corrigées, le Client sera libre d’annuler la Commande corrigée sans pénalité.

    <br/><br/><span>9. Commande</span>
    <br/>Une procédure est mise en place avec des étapes successives et à respecter pour aboutir à la validation de la commande.

    <br/>Le client peut sélectionner autant de Produits qu'il le souhaite qui s'ajouteront au"panier" qui récapitule les Produits choisis par le client ainsi que les prix afférents.
    <br/>Le client pourra librement modifier le panier avant validation de la commande. La validation de la commande vaut confirmation de l'acceptation par le Client des CGV, des produits achetés, de leur prix.
    <br/>Un email de confirmation validant la Commande sera adressé au Client par MAISON BLEU LIN. A cet effet, le Client accepte formellement l'usage du courrier électronique
    pour la confirmation par MAISON BLEU LIN du contenu de sa commande. Faute de réponse du client sous 48h, la commande sera annulée.
    <br/>La facture sera également transmise au Client par email, à la suite de la confirmation de commande. Seules les personnes juridiquement capables de souscrire des contrats peuvent commander des Produits sur le Site Internet. Lors de la passation de la Commande,
    le Client garantit avoir la pleine capacité juridique pour adhérer aux présentes Conditions Générales et ainsi conclure le présent contrat. Lors de la Commande, le Client accepte de fournir les informations qui lui sont demandées et s’engage sur la véracité de ces dernières: nom et prénom, adresse, téléphone, adresse électronique,
    Le Client doit vérifier l’ensemble des renseignements saisis au cours de sa Commande et sur la page de validation de la Commande
    (Produit commandé, adresse de livraison, adresse de facturation, coordonnées téléphoniques…). Si, après validation de sa Commande en ligne,
    le Client se rend compte de la nécessité de modifier certaines informations, il peut le faire en contactant le service client de MAISON BLEU LIN
    par email claude@maisonbleulin.com immédiatement afin que ce dernier puisse satisfaire au mieux sa demande.
    <br/>MAISON BLEU LIN se réserve le droit de retirer à tout moment tout produit affiché sur le Site et de remplacer ou modifier tout contenu ou information figurant sur ce dernier.

    <br/>Malgré les meilleurs efforts pour satisfaire les attentes du Client, il se peut que MAISON BLEU LIN soit amenée à refuser de traiter une Commande après avoir adressé au client l'email de confirmation récapitulant la Commande. MAISON BLEU LIN ne saurait être tenue responsable envers le client ou un tiers des conséquences dommageables du retrait d'un produit du Site, ou du remplacement ou de la modification de tout contenu ou information figurant sur ce Site, ou encore du refus de traiter une commande après l'envoi de l'email de confirmation récapitulant la commande.

    <br/>MAISON BLEU LIN se réserve également le droit de refuser ou d'annuler une Commande provenant d'un client avec lequel elle a un litige sur le paiement d'une précédente Commande ou une suspicion objective de fraude.
    <br/>MAISON BLEU LIN ne saurait être tenue responsable d’éventuelles erreurs de saisie par le Client, ni de leurs conséquences en terme de retard ou d’erreur de livraison.
    <br/>Ainsi, si la livraison ne peut pas avoir lieu à cause d’une erreur de saisie par le Client, ou d’une mention erronée, les frais de réexpédition seront à la charge du Client.
    <br/>Dans ce cas, MAISON BLEU LIN ne pourra être tenue responsable du non respect des délais de livraison. MAISON BLEU LIN envoie un courrier électronique de confirmation de la Commande du Client à l’adresse électronique qu’il aura communiqué
    afin de récapituler les informations contractuelles. Ce courrier électronique est adressé après validation de ladite Commande et encaissement du règlement associé. Les Commandes sont traitées dans l´ordre dans lequel elles sont effectivement enregistrées par les systèmes informatiques de MAISON BLEU LIN.
    <br/>MAISON BLEU LIN s’engage à traiter toutes les Commandes, dans la limite des stocks disponibles.

    <br/><br/><span>10. Paiement</span>
    <br/>Plusieurs solutions de paiement seront proposées au Client par MAISON BLEU LIN : CB au téléphone (aux frais de MAISON BLEU LIN qui appelle le Client, à l'heure de son choix
    et prend à sa charge le coût dudit appel, quelle que soit la destination), virement bancaire, chèque postal ou bancaire, solutions de paiement Wise et Pay Pal.
    <br/>Le Client ayant décidé de régler son achat par chèque postal ou bancaire doit envoyer son chèque au vendeur, l'entreprise Maison Bleu Lin, le jour même de la commande effective.
    <br/>Si le paiement par chèque bancaire ou postal français n'est pas parvenu au vendeur dans un délai maximum de 5 jours ouvrés, le vendeur se réserve le droit d'annuler la commande en totalité.

    <br/><br/>Le Client aura la possibilité de regrouper différentes Commandes étalées sur plusieurs jours ou semaines dans un seul envoi (Livraison).
    <br/>Par contre, le paiement de chaque Commande devra être avoir été effectué avant de pouvoir procéder à une nouvelle Commande.
    <br/>Le paiement des frais d'envoi se fera en toute fin, juste avant la livraison, une fois choisi le Transporteur, déterminé le mode d'envoi
    et calculés au plus juste ces mêmes frais d'envoi, en fonction de la destination du ou des colis.

    <br/><br/><span>11. Responsabilité</span>

    <br/>MAISON BLEU LIN ne peut voir sa responsabilité engagée pour l’inexécution du contrat en cas de rupture de stock ou indisponibilité du Produit,
    de force majeure, de perturbation ou grève totale ou partielle notamment des services postaux et moyens de transport, communications (panne du réseau informatique etc.),
    inondation, incendie, intempérie exceptionnelle… MAISON BLEU LIN ne pourra être tenue responsable, envers un Client ou un tiers,
    d’aucun dommage indirect, d’aucune perte de profit ou de chiffre d’affaires ou d’aucune perte de données survenue de quelque manière que ce soit.

    <br/><br/><span>12. Livraison</span>

    <br/>Sauf dispositions contraires stipulées aux présentes Conditions Générales, ou sur le Site Internet lors de la validation par le Client de la Commande,
    les frais de livraison sont toujours à la charge du Client et non inclus dans le prix du Produit. La Poste est le principal Transporteur avec lequel MAISON BLEU LIN traite pour l'envoi des colis. Les Produits sont obligatoirement livrés à l’adresse indiquée par le Client lors de la saisie des renseignements au cours de sa Commande.
    <br/>Le Client doit vérifier le colis à son arrivée et dispose d'un délai maximal de 48 heures pour ce faire.

    <br/>Si le Client ne peut pas être présent à l’adresse qu’il a indiquée le jour de la livraison, un avis de passage est déposé dans la boîte aux lettres du Client.
    <br/>Il appartient alors au Client d’aller retirer sa Commande dans son bureau de poste et ce, dans les 10 jours ouvrés suivant le dépôt de l’avis de passage. Passé ce délai de 10 jours ouvrés , le Produit est réexpédié par la Poste à MAISON BLEU LIN. Si le Client décide que soit procédé à une nouvelle livraison, celle-ci est à sa charge et doit être acquittée avant la réexpédition en contactant le service clients de MAISON BLEU LIN. Si le Client souhaite annuler sa Commande, il peut choisir soit le remboursement, soit un avoir, les frais de port n’étant pas remboursés.
    <br/>Si le Client choisit le remboursement, MAISON BLEU LIN s'engage à rembourser le Client dans un délai de trente (30) jours à compter de la date de réception du Produit retourné. Si le Client a saisi une adresse incorrecte lors de sa Commande, la Poste sera incapable d’effectuer la livraison et le Produit sera automatiquement retourné à MAISON BLEU LIN.
    <br/>Si le Client décide que soit procédé à une nouvelle livraison, celle-ci est à sa charge et doit être acquittée avant la réexpédition en contactant le service clients de MAISON BLEU LIN. A la livraison du Produit, le Client doit obligatoirement vérifier l’aspect extérieur du colis et le refuser en cas de détérioration.

    <br/><br/>Les délais de livraison varient en fonction du Transporteur et sont indiqués sur la page de validation de la Commande ou le cas échéant dans courrier électronique de validation de la Commande.
    <br/>Les délais mentionnés ne sont qu’indicatifs, toutefois la date limite de livraison est fixée pour une adresse en France métropolitaine au jour du paiement + 15 jours
    et pour les autres destinations au jour du paiement + 20 jours.
    <br/>En cas de non réception d’un colis dans les délais indicatifs, le Client peut contacter MAISON BLEU LIN qui déterminera la cause du retard et la communiquera au Client.
    <br/>Le Client peut résilier sa commande par courrier recommandé avec Accusé de Réception s’il n’est pas livré dans les 7 jours à partir de la date maximum de livraison mais moins de 60 jours après la date maximum de livraison.
    <br/>En cas de défauts apparents, l’acheteur bénéficie du droit de retour dans les conditions prévues dans ce document. Sont considérés comme cas de force majeure déchargeant le vendeur de son obligation de livrer, la guerre, l’émeute, l’incendie, les grèves, les accidents et l’impossibilité d’être approvisionnés. Le professionnel est responsable de plein droit à l'égard du consommateur de la bonne exécution des obligations résultant du contrat conclu à distance, que ces obligations soient exécutées par le professionnel qui a conclu ce contrat ou par d'autres prestataires de services, sans préjudice de son droit de recours conte ceux-ci. Toutefois, il peut s'exonérer de tout ou partie de sa responsabilité en apportant la preuve que l'inexécution ou la mauvaise exécution du contrat est imputable soit au consommateur, soit au fait, imprévisible et insurmontable, d'un tiers au contrat, soit à un cas de force majeure.

    <br/><br/><span>13.Droit de rétractation</span>

    <br/>Article L. 121-21. à L.121-21-8 du Code de la Consommation. Vous disposez de 14 jours (à compter de la réception des articles) pour vous faire une opinion.
    <br/>A noter que les Produits vendus au mètre à savoir les rubans, les dentelles et les tissus et spécialement découpés pour le Client ne seront ni repris ni échangés ni remboursés.
    <br/>Seuls les Produits entiers pourront faire l'objet d'un remboursement ou d'échange.
    <br/>En cas d’échange ou remboursement, renvoyez le (les) Produits(s) neuf(s), intact(s), accompagné(s) de tous les accessoires éventuels, notices d’emploi et documentations via le formulaire suivant :
    <br/>Veuillez compléter et renvoyer le présent formulaire uniquement si vous souhaitez vous rétracter du contrat :

    <br/><br/>A l'attention de :

    <br/><br/>MAISON BLEU LIN
    <br/>9, rue du Petit Fort ( Jerzual)
    <br/>22100 DINAN

    <br/>Je/Nous vous notifie/notifions par la présente ma/notre rétractation du contrat portant sur la
    vente du bien ci-dessous :

    <br/><br/>Commandé le ….................................... / Reçu le ….......................................................
    <br/>Nom du consommateur : ….............................................................................................
    <br/>Adresse du consommateur : …........................................................................................
    <br/>…................................................................................................................................

    <br/><br/>Signature du consommateur :


    <br/><br/><br/>Date :

    <br/>Lorsque le droit de rétractation est exercé, le professionnel est tenu de rembourser le consommateur de la totalité des sommes versées, y compris les frais de livraison, sans retard injustifié et au plus tard dans les quatorze jours à compter de la date à laquelle il est informé de la décision du consommateur de se rétracter. Pour les contrats de vente de biens, à moins qu'il ne propose de récupérer lui-même les biens, le professionnel peut différer le remboursement jusqu'à récupération des biens ou jusqu'à ce que le consommateur ait fourni une preuve de l'expédition de ces biens, la date retenue étant celle du premier de ces faits. Au-delà, les sommes dues sont de plein droit majorées du taux d'intérêt légal si le remboursement intervient au plus tard dix jours après l'expiration des délais fixés aux deux premiers alinéas, de 5 % si le retard est compris entre dix et vingt jours, de 10 % si le retard est compris entre vingt et trente jours, de 20 % si le retard est compris entre trente et soixante jours, de 50 % entre soixante et quatre-vingt-dix jours et de cinq points supplémentaires par nouveau mois de retard jusqu'au prix du produit, puis du taux d'intérêt
    légal. Le professionnel effectue ce remboursement en utilisant le même moyen de paiement que celui utilisé par le consommateur pour la transaction initiale, sauf accord exprès du consommateur pour qu'il utilise un autre moyen de paiement et dans la mesure où le remboursement n'occasionne pas de frais pour le consommateur. Le professionnel n'est pas tenu de rembourser les frais supplémentaires si le consommateur a expressément choisi un mode de livraison plus coûteux que le mode de livraison standard proposé par le professionnel.

    <br/><br/>Attention, certains Produits sont vendus à la coupe (rubans, dentelles, tissus) et ne seront en aucun cas ni remboursés ni échangés. Ces Produits vendus par longueur spécifique, faite à la demande expresse du client puisque lui seul a validé la quantité requise, n’entrent pas dans le champ d’application du droit de rétractation. Le droit de rétractation ne s’applique qu’aux objets vendus à la pièce ou en un seul contenant (bocal, sac, sachets, bouteille, blister, enveloppe...).
    <br/><br/><span>14. Clause de réserve de propriété</span>
    <br/>Il est expressément convenu que l'entreprise Maison Bleu Lin se réserve la propriété des marchandises jusqu’au paiement intégral de leur prix.
    <br/>A cet égard, la remise de tout titre créant une obligation de payer ne constitue pas un paiement au sens de la présente clause.

    <br/><br/>L’acheteur (le client) est autorisé, dans le cadre de son exploitation normale, sauf s’il se trouve en état de cessation de paiement, à revendre les marchandises livrées. Il ne peut ni les donner en gage, ni en transférer la propriété à titre de garantie.
    <br/>En cas de saisie ou de toute autre intervention d’un tiers, l’acheteur (le client) est tenu d’aviser l'entreprise Maison Bleu Lin immédiatement.
    <br/>Dans le cadre de l’application de la présente clause, l’acheteur (le client) supportera la charge des risques en cas de perte ou de destruction dès la livraison des marchandises.

    <br/><br/><span>15. Litiges</span>
    <br/>Le présent contrat est soumis au droit français. L’entreprise MAISON BLEU LIN ne peut être tenue pour responsable des dommages de toute nature, tant matériels qu’immatériels ou corporels, qui pourraient résulter d’un mauvais fonctionnement ou de la mauvaise utilisation des produits commercialisés. Il en est de même pour les éventuelles modifications des produits résultant des fabricants. La responsabilité de l’entreprise MAISON BLEU LIN sera, en tout état de cause, limitée au montant de la commande et ne saurait être mise en cause pour de simples erreurs ou omissions qui auraient pu subsister malgré toutes les précautions prises dans la présentation des produits En cas de difficultés dans l’application du présent contrat, l’acheteur a la possibilité, avant toute action en justice, de rechercher une solution amiable notamment avec l’aide : d’une association professionnelle de la branche, d’une association de consommateurs ou de tout autre conseil de son choix . Il est rappelé que la recherche de la solution amiable n’interrompt pas le " bref délai " de la garantie légale, ni la durée de la garantie contractuelle. Il est rappelé qu’en règle générale et sous réserve de l’appréciation des Tribunaux, le respect des dispositions du présent contrat relatives à la garantie contractuelle suppose : que l’acheteur honore ses engagements financiers envers le vendeur. Les réclamations ou contestations seront toujours reçues avec bienveillance attentive, la bonne foi étant toujours présumée chez celui qui prend la peine d’exposer sa situation. En cas de litige, le client s’adressera par priorité à l’entreprise pour obtenir une solution amiable.
    <br/>A défaut, le Tribunal de Commerce St Malo (Ille-et-Vilaine) est seul compétent, quels que soient le lieu de livraison et le mode de paiement acceptés.

    <br/><br/><span>16. Garantie</span>

    <br/>Article L. 211-4. à L. 211-14. du Code de la Consommation et garantie commerciale Article L. 211-15. à L. 211-16-1. L’entreprise MAISON BLEU LIN ne pourra être tenue pour responsable pour non respect des dispositions réglementaires et législatives en vigueur dans le pays de réception, la responsabilité de l’entreprise MAISON BLEU LIN est systématiquement limitée a la valeur du produit mis en cause, valeur à sa date de vente et ce sans possibilités de recours envers la marque ou la société productrice du produit. En toute hypothèse, le client bénéficie de la garantie légale d’éviction et des vices cachés (Art.1625 et suivants du Code Civil). A la condition que l’acheteur fasse la preuve du défaut caché, le vendeur doit légalement en réparer toutes les conséquences (art.1641 et suivants du code civil) ; si l’acheteur s’adresse aux tribunaux, il doit le faire dans un délai deux ans à compter de la découverte du vice caché pour mettre en oeuvre la procédure civile. Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. Vous pouvez contacter le service clients : MAISON BLEU LIN 9, rue du Petit Fort 22100 DINAN claude@maisonbleulin.com

    <br/><br/><span>17. Responsabilité du vendeur</span>

    <br/>Responsabilité du professionnel dans le cadre de la vente à distance : Le professionnel est responsable de plein droit à l'égard du consommateur de la bonne exécution des obligations résultant du contrat conclu à distance, que ces obligations soient exécutées par le professionnel qui a conclu ce contrat ou par d'autres prestataires de services, sans préjudice de son droit de recours contre ceux-ci. Toutefois, il peut s'exonérer de tout ou partie de sa responsabilité en apportant la preuve que l'inexécution ou la mauvaise exécution du contrat est imputable soit au consommateur, soit au fait, imprévisible et insurmontable, d'un tiers au contrat, soit à un cas de force majeure.
    <br/>MAISON BLEU LIN ne peut voir sa responsabilité engagée pour l’inexécution du contrat en cas de rupture de stock ou indisponibilité du Produit,
    de force majeur, de perturbation ou grève totale ou partielle notamment des services postaux et moyens de transport, communications (panne du réseau informatique etc.),
    inondation, incendie, intempérie exceptionnelle… MAISON BLEU LIN ne pourra être tenu responsable, envers un Client ou un tiers,
    d’aucun dommage indirect, d’aucune perte de profit ou de chiffre d’affaires ou d’aucune perte de données survenue de quelque manière que ce soit.</p>
</div>
<div class="container p-3" *ngIf="lang === 'en'">
  <h1>Terms and Conditions</h1>
  <p class="cgv-text p-3">
    <span>Terms and Conditions</span>


    <br/><br/><span>1. Definition</span>
    <br/>In these Terms and Conditions of sale (the "T&C"), the term "Customer" shall mean any person wishing to acquire one or more products offered for sale (the "Product(s)")
    on the website www.maisonbleulin.com (the "Website") in compliance with the Terms and Conditions.
    <br/>Each order made by a Customer under the conditions of these Terms and Conditions will hereinafter be referred to as an "Order".

    <br/> <br/>The companies MAISON BLEU LIN uses for the delivery of the products to the customer are hereinafter referred to as "Carriers".

    <br/><br/><span>2. Purpose of the Terms & Conditions</span>
    <br/>The purpose of the Terms & Conditions (T&C) is to define the rights and obligations of the parties in the context of the sale on the Website of the products offered by MAISON BLEU LIN to the Customer.
    <br/>The Customer acknowledges having read, at the time of placing the Order, the Terms & Conditions stated on this screen. In this sense, the Client acknowledges that he accepts without reservation all the provisions set out in the present conditions below.
    <br/>These Terms & Conditions shall prevail over any other terms and conditions contained in any other document, unless previously waived in writing. Insofar as the Customer orders online the Products presented on the Website, the electronic signature of the Customer on the validation page of the Order, marks the conclusion of the contract between the Customer and MAISON BLEU LIN. In accordance with 2000 March 13th law text on electronic signature and the 2004 June 21st law on confidence for the digital economy, any Order validated by the Customer by his "double click" or by his agreement on the phone constitutes an irrevocable acceptance of these Terms and Conditions by the Customer, like a handwritten signature.

    <br/><br/><span>3. The Website</span>
    <br/>Any Order validated by a Customer on the Website implies the prior acceptance of these Terms and Conditions. The Website is operated by :
    <br/>MAISON BLEU LIN, individual company, whose head office is located at 9 rue du petit fort, 22100 DINAN, registered at St Malo RCS Commerce Registree Office under the number 419 939 715 000 19
    <br/>VAT Not applicable, Article 293B of the French General Tax Code.
    <br/>The Website is hosted by the company Agence 11h10, limited company owned by Sylvain Guéné, whose registered office is located at Parc d'Activités Beaujardin, bâtiment Rhedae, CHATEAUGIRON, registered with the Rennes Commerce Office under the number RCS Rennes B 842 045 452

    <br/> <br/>www.maisonbleulin is an e-commerce website owned and managed by Maison Bleu Lin. The website is accessible to all users of the Internet.
    <br/>Maison Bleu Lin cannot be held responsible for any damage of any kind resulting from the unavailability of the site.
    <br/>Maison Bleu Lin cannot guarantee that the site will be free of anomalies, errors or bugs, nor that the site will function without breakdown or interruption.
    <br/>In this respect, Maison Bleu Lin may freely determine at its own discretion any period of unavailability of the site or its content.
    <br/>Maison Bleu Lin cannot be held responsible for any problems with data transmission, connection or network unavailability.
    <br/>Maison Bleu Lin reserves the right to make changes to the Web Site for technical or commercial reasons. Where such changes do not materially or adversely affect the provision of the services, the client may be informed of the changes, but acceptance is not required.

    <br/><br/><span>4. Validity of the Terms and Conditions</span>
    <br/>MAISON BLEU LIN reserves the right to modify its Terms and Conditions at any time and for any reason. However, the Products ordered on the Website by the Clients are governed by the Terms and Conditions online at the date of the Order and accepted by the Client.

    <br/><br/><span>5. Duration of the contract</span>
    <br/>The Terms and Conditions are concluded for the duration necessary for the supply of the Products, until the expiry of their guarantees.0

    <br/><br/><span>6. Documentary evidence</span>
    <br/>The contractual information about the Order will be confirmed by email.
    <br/>MAISON BLEU LIN recommends that the Client keep the confirmation email or print it.
    <br/>However, all documents resulting from the archiving in the computer systems of MAISON BLEU LIN may also constitute legal proof of any communication, any Order, and any payment made between the Parties.

    <br/><br/><span>7. The goods</span>
    <br/>Any professional seller of goods must, before the conclusion of the contract, put the consumer in a position to know the essential characteristics of the good.
    <br/>The goods offered on the site constitute an advertisement and are therefore subject to the provisions of Article L 121-1 of the Consumer Code.
    <br/>The Products offered for sale are those described on the site on the day the customer consults the site, within the limits of available stocks.
    <br/>However, MAISON BLEU LIN is not responsible for any error in the update, whatever its origin.
    <br/>MAISON BLEU LIN will do its best to propose an alternative solution to the Customer in this case, without being able to guarantee it.
    <br/>MAISON BLEU LIN will do its best to present the Products and their essential characteristics. However, it is possible that errors may appear on the website, which the Client acknowledges and accepts. In general, the descriptions (photographs, texts, graphics, information and characteristics) illustrating the Products presented are given as an indication.
    <br/>In case of error in the description (photographs, texts, graphics, information or characteristics) of a Product, MAISON BLEU LIN cannot be held responsible.
    <br/>MAISON BLEU LIN is committed to honouring the orders received within the limits of available stocks. If the product is not available, MAISON BLEU LIN will inform the Customer as soon as possible and offer him a similar product, a refund or a credit note on all the unavailable products.
    <br/>If the customer chooses a refund, MAISON BLEU LIN will try to refund the customer within thirty (30) days from the date MAISON BLEU LIN informed the customer of the unavailability of the product.

    <br/><br/><span>8. Prices of the Products</span>
    <br/>The prices of the Products sold on the Website are indicated in euros excluding VAT (art. 293B of the French General Tax Code) and do not include shipping costs. They are valid as long as they appear on the product description page on the Website. The price indicated on the product sheets does not include transport: this varies according to the carrier, the chosen solution (home delivery or delivery point), the weight of the order and the destination.
    <br/>All prices are given subject to obvious typographical errors. If an error occurs MAISON BLEU LIN will contact the Customer to inform him of the error and remind him that the Order will be charged at the corrected conditions.
    <br/>However, in case of refusal of the corrected conditions, the Customer will be free to cancel the corrected Order without penalty.

    <br/><br/><span>9. Order</span>
    <br/>A procedure is set up with successive steps that must be followed to validate the order.
    <br/>The customer can select as many Products as he/she wishes, which will be added to the "basket" which summarises the Products chosen by the Customer as well as the related prices.
    <br/>The customer may freely modify the basket before validating the order. Validation of the order confirms the Client's acceptance of the Terms and Conditions, the Products purchased and their prices.
    <br/>An email confirming the order will be sent to the Customer by MAISON BLEU LIN. To this end, the Customer formally accepts the use of email for the confirmation by MAISON BLEU LIN of the content of his order. Failure by the Customer to respond to the aforementioned email within 48 hours will result in the cancellation of the order.
    <br/>The invoice will also be sent to the Customer by email, following the order confirmation. Only persons legally capable of entering into contracts can order Products on the Website. By placing the Order, the Customer warrants that he/she has the full legal capacity to accept these Terms and Conditions and to enter into this contract. When placing an Order, the Customer agrees to provide the information requested and undertakes to ensure that this information is accurate: first and last name, address, telephone number, e-mail address, etc. The Customer must check all the information entered during the Order process and on the Order validation page (Product ordered, delivery address, billing address, telephone number, etc.). If, after validation of the online Order, the Customer realizes that he/she needs to modify some information, he/she can do so by contacting MAISON BLEU LIN's customer service by email claude@maisonbleulin.com immediately so that the latter can best satisfy his/her request.

    <br/> <br/>MAISON BLEU LIN reserves the right to remove at any time any product displayed on the Website and to replace or modify any content or information on the Website.
    <br/>Despite the best efforts to satisfy the customer's expectations, MAISON BLEU LIN may have to refuse to process an Order after sending the customer the confirmation email summarising the Order. MAISON BLEU LIN shall not be liable to the customer or any third party for any damages resulting from the removal of a product from the Website, or the replacement or modification of any content or information on this Website, or the refusal to process an order after sending the confirmation email summarising the order.

    <br/> <br/>MAISON BLEU LIN also reserves the right to refuse or cancel an order from a customer with whom it has a dispute over the payment of a previous order or an objective suspicion of fraud.

    <br/> <br/>MAISON BLEU LIN cannot be held responsible for any typing errors made by the Client, nor for their consequences in terms of delay or delivery error.
    <br/>Thus, if the delivery cannot take place because of an input error by the Customer, or because of an erroneous mention, the costs of reshipment will be charged to the Customer.
    <br/>In this case, MAISON BLEU LIN will not be held responsible for not respecting the delivery time. MAISON BLEU LIN sends an email confirming the Customer's order to the email address provided by the Customer in order to summarise the contractual information. This email is sent after validation of the Order and receipt of the associated payment. Orders are processed in the order in which they are actually registered by the computer systems of MAISON BLEU LIN.
    <br/>MAISON BLEU LIN is committed to process all Orders, within the limits of available stocks.

    <br/><br/><span>10. Payment</span>
    <br/>Several payment solutions will be offered to the Customer by MAISON BLEU LIN: credit card over the phone (at the expense of MAISON BLEU LIN which calls the Customer at the time of his choice and pays the cost of the call, regardless of the destination), bank transfer, postal or bank check, Wise and Pay Pal payment solutions.
    <br/>If the Customer chooses to pay by cheque, he/she must send the cheque to the seller, Maison Bleu Lin, on the same day as the order is placed.
    <br/>If the payment by French postal or bank cheque is not received by the seller within a maximum of 5 working days, the seller reserves the right to cancel the order in its entirety.
    <br/>Grouping several orders option for one sole shipment :
    <br/>The Customer shall have the option of grouping different Orders spread over several days or weeks into a single shipment (Delivery).
    <br/>However, payment for each Order must be made before a new Order can be placed.

    <br/> <br/>The payment of the shipping costs will be made at the end, just before the delivery, once the Carrier has been chosen, the shipping method has been determined and the shipping costs have been calculated as accurately as possible, depending on the destination of the package(s).

    <br/><br/><span>11. Responsibility</span>
    <br/>MAISON BLEU LIN cannot be held responsible for the non-execution of the contract in case of stock shortage or unavailability of the product, force majeure, disruption or total or partial strike of postal services and means of transport, communications (computer network failure etc.), flood, fire, exceptional weather... MAISON BLEU LIN cannot be held responsible, towards a Customer or a third party, for any indirect damage, loss of profit or turnover or loss of data occurring in any way.

    <br/><br/><span>12. Delivery</span>
    <br/>Unless otherwise stipulated in these Terms and Conditions, or on the Website when the Customer validates the Order, the delivery costs are always at the expense of the Customer and not included in the price of the Product. La Poste is the main carrier with which MAISON BLEU LIN deals for the delivery of the packages. The Products are necessarily delivered to the address indicated by the Customer when entering the information during the Order.
    <br/>The Customer must check the package upon arrival and has a maximum of 48 hours to do so.
    <br/>If the Customer is unable to be present at the address indicated on the day of delivery, a delivery notice shall be left in the Customer's letterbox.
    <br/>It is then up to the Customer to collect the Order from the post office within 10 working days of the delivery notice being left. After this period of 10 working days, the product is returned by the Post Office to MAISON BLEU LIN. If the Customer decides to have a new delivery, it is at his expense and must be paid before the reshipment by contacting the customer service of MAISON BLEU LIN. If the Customer wishes to cancel the Order, he can choose either a refund or a credit note, the shipping costs not being refunded.
    <br/>If the Customer chooses the refund, MAISON BLEU LIN will refund the Customer within thirty (30) days from the date of receipt of the returned Product. If the Customer has entered an incorrect address in the Order, the Post Office will be unable to deliver and the Product will automatically be returned to MAISON BLEU LIN.
    <br/>If the Customer decides to have a new delivery made, this is at his/her expense and must be paid for before the return by contacting MAISON BLEU LIN customer service. Upon delivery of the product, the Customer must check the external aspect of the package and refuse it in case of deterioration.

    <br/> <br/>Delivery times vary depending on the Carrier and are indicated on the Order validation page or in the Order validation email.
    <br/>The times mentioned are only indicative, however the delivery deadline is set for an address in mainland France on the day of payment + 15 days and for other destinations on the day of payment + 20 days.
    <br/>In case of non-receipt of a package within the indicative time, the Customer can contact MAISON BLEU LIN who will determine the cause of the delay and communicate it to the Customer.
    <br/>The Customer can cancel his order by registered mail with acknowledgement of receipt if he is not delivered within 7 days from the maximum delivery date but less than 60 days after the maximum delivery date.
    <br/>In the event of apparent defects, the buyer has the right to return the goods under the conditions set out in this document. War, riot, fire, strikes, accidents and the impossibility of being supplied shall be considered as force majeure discharging the seller from his obligation to deliver. The trader shall be fully liable to the consumer for the proper performance of the obligations arising from the distance contract, irrespective of whether these obligations are performed by the trader who concluded the contract or by other service providers, without prejudice to his right of recourse against them. However, he may be exempted from all or part of his liability by proving that the failure to perform or improper performance of the contract is attributable either to the consumer or to the unforeseeable and insurmountable act of a third party to the contract, or to force majeure.


    <br/><br/><span>13. Right of withdrawal</span>
    <br/>Article L. 121-21. to L.121-21-8 of the Consumer Code. You have 14 days (from receipt of the items) to make up your mind.
    <br/>Please note that Products sold by the metre, i.e. ribbons, laces and fabrics, specially cut for the Customer, will not be returned, exchanged or refunded.
    <br/>Only whole Products may be refunded or exchanged.
    <br/>In the event of an exchange or refund, return the Product(s) new, intact, accompanied by all possible accessories, instructions and documentation via the following form:
    <br/>Please complete and return this form only if you wish to withdraw from the contract:

    <br/> <br/>To the attention of :

    <br/> <br/>MAISON BLEU LIN
    <br/>9, rue du Petit Fort ( Jerzual)
    <br/>22100 DINAN
    <br/>I/We hereby notify you of my/our withdrawal from the contract for the sale of the goods below:

    <br/> <br/>Ordered on ....................................... / Received on ..........................................................
    <br/>Name of consumer: ................................................................................................
    <br/>Consumer's address: ...........................................................................................
    <br/>...................................................................................................................................

    <br/> <br/>Signature of consumer :

    <br/> <br/>Date :
    <br/>Where the right of withdrawal is exercised, the trader shall be required to reimburse the consumer for all sums paid, including the cost of delivery, without undue delay and no later than fourteen days from the day on which he receives the consumer's decision to withdraw. For contracts for the sale of goods, unless the trader offers to collect the goods himself, the trader may withhold the reimbursement until he has collected the goods or until the consumer has provided proof of dispatch of the goods, whichever is the earlier. Beyond that date, the sums due shall automatically be increased by the legal interest rate if the reimbursement is made no later than ten days after the expiry of the time limits set out in the first two paragraphs, by 5% if the delay is between ten and twenty days, by 10% if the delay is between twenty and thirty days, by 20% if the delay is between thirty and sixty days, by 50% between sixty and ninety days and by five additional points for each additional month of delay up to the price of the product, and then by the legal interest rate. The trader shall make the refund using the same means of payment as the one used by the consumer for the original transaction, unless the consumer expressly agrees to use another means of payment and insofar as the refund does not incur any costs for the consumer. The trader shall not be obliged to reimburse additional costs if the consumer has expressly chosen a more expensive delivery method than the standard delivery method offered by the trader.

    <br/>Please note that a few Products are sold by the cut (ribbons, laces, fabrics) and will not be refunded or exchanged under any circumstances. These Products sold by specific length, made at the express request of the customer since he alone has validated the required quantity, do not fall within the scope of the right of withdrawal. The right of withdrawal only applies to items sold by the piece or in a single container (jar, bag, sachet, bottle, blister pack, envelope, etc.).

    <br/><br/><span>14. Reservation of ownership clause</span>
    <br/>It is expressly agreed that Maison Bleu Lin retains ownership of the goods until full payment of the price.
    <br/>In this respect, the delivery of any document creating an obligation to pay does not constitute payment within the meaning of the present clause.

    <br/> <br/>The buyer (the customer) is entitled to resell the delivered goods in the course of its normal business operations, unless it is in a state of suspension of payments. He may not pledge the goods or transfer ownership of the goods by way of security.
    <br/>In the event of seizure or any other intervention by a third party, the purchaser (the customer) is obliged to notify Maison Bleu Lin immediately.
    <br/>In the context of the application of the present clause, the buyer (the client) will bear the risk of loss or destruction of the goods upon delivery.


    <br/><br/><span>15. Disputes</span>

    <br/> <br/>This contract is subject to French law. MAISON BLEU LIN cannot be held responsible for damages of any kind, material or immaterial, which could result from a malfunction or misuse of the products sold. The same is true for any changes in the products resulting from the manufacturers. The responsibility of MAISON BLEU LIN will be, in any case, limited to the amount of the order and can not be held responsible for simple errors or omissions that could have remained despite all precautions taken in the presentation of products. In case of difficulties in the application of this contract, the buyer has the opportunity, before any legal action, to seek an amicable solution with the help of: a professional association of the branch, a consumer association or any other advice of his choice. It is recalled that the search for an amicable solution does not interrupt the "short period" of the legal guarantee, nor the duration of the contractual guarantee. It is recalled that as a general rule and subject to the appreciation of the Courts, compliance with the provisions of this contract relating to the contractual guarantee assumes: that the buyer honours his financial commitments to the seller. Complaints or disputes will always be received with attentive benevolence, good faith always being presumed in the person who takes the trouble to explain his situation. In the event of a dispute, the customer shall first contact the company to obtain an amicable solution.

    <br/> <br/>Failing this, the Commercial Court of St Malo (Ille-et-Vilaine) shall have sole jurisdiction, regardless of the place of delivery and the method of payment accepted.

    <br/><br/><span>16. Guarantee</span>
    <br/>Article L. 211-4. to L. 211-14. of the Consumer Code and commercial guarantee Article L. 211-15. to L. 211-16-1. MAISON BLEU LIN cannot be held responsible for non-compliance with the regulations and laws in force in the country of receipt, the responsibility of MAISON BLEU LIN is systematically limited to the value of the product in question, value at the date of sale and without possibility of recourse to the brand or the company producing the product. In any case, the customer benefits from the legal guarantee of eviction and hidden defects (Art.1625 and following of the Civil Code). On the condition that the buyer proves the hidden defect, the seller must legally repair all the consequences (Art.1641 and following of the Civil Code); if the buyer goes to court, he must do so within two years of the discovery of the hidden defect in order to initiate civil proceedings. The seller is obliged to deliver goods in conformity with the contract and is liable for any lack of conformity existing at the time of delivery. He is also liable for any lack of conformity resulting from the packaging, the assembly instructions or the installation when the latter was made his responsibility by the contract or was carried out under his responsibility.
    <br/>You can contact the customer service: MAISON BLEU LIN 9, rue du Petit Fort 22100 DINAN claude@maisonbleulin.com

    <br/><br/><span>17. Liability of the trader</span>
    <br/>Liability of the trader in distance selling: The trader shall be fully liable to the consumer for the proper performance of the obligations arising from the distance contract, irrespective of whether these obligations are performed by the trader who concluded the contract or by other service providers, without prejudice to his right of recourse against them. However, he can be exonerated from all or part of his responsibility by proving that the non-performance or the bad performance of the contract is attributable either to the consumer, or to the unforeseeable and insurmountable act of a third party to the contract, or to a case of force majeure.
    <br/>MAISON BLEU LIN cannot be held responsible for the non-execution of the contract in case of stock shortage or unavailability of the product, force majeure, disruption or total or partial strike of postal services and means of transport, communications (computer network failure etc.), flood, fire, exceptional weather... MAISON BLEU LIN cannot be held responsible, towards a customer or a third party, for any indirect damage, loss of profit or turnover or loss of data occurring in any way.
  </p>
</div>
