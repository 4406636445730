import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {environment} from "../../../../environments/environment";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer) { }

  S3 = `${environment.s3}`;

  @Input('carousel') slickModal!: SlickCarouselComponent;
  @Input('slider') slider: any;

  ngOnInit(): void {
  }

  prev() {
    this.slickModal.slickPrev();
  }

  next() {
    this.slickModal.slickNext();
  }
}
