import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {parseHtmlGracefully} from "@angular/core/schematics/utils/parse_html";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  notifyTopRight(message: string) {
    return this.snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['snackbar-success']
    });
  }

  notifyTopRightFail(message: string) {
    return this.snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['snackbar-fail']
    });
  }
}
