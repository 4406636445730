<footer class="bg-green">
  <div class="container-footer">
    <div class="row align-items-start">
      <div class="logo-footer-container">
        <img class="img-fluid mb-3" src="/assets/images/logo.png" alt="logo-maison-bleulin">
        <p class="text-footer-bleu">{{'FOOTER.PITCH'|translate}}</p>
      </div>
      <div class="col-lg-4 mt-4 text-white text-center text-lg-start">
        <div class="row align-items-start">
          <div class="col-lg-4">
            <h4 class="text-white">{{'FOOTER.CATALOG.HEADER'|translate}}</h4>
            <div class="border-title-footer"></div>
            <div class="link-footer-container">
              <a [routerLink]="'/catalogue/brocante'">{{'FOOTER.CATALOG.FLEA-MARKET'|translate}}</a>
              <a [routerLink]="'/catalogue/creations'">{{'FOOTER.CATALOG.CREATIONS'|translate}}</a>
              <a [routerLink]="'/catalogue/mercerie'">{{'FOOTER.CATALOG.HABERDASHERY'|translate}}</a>
              <a [routerLink]="'/catalogue/textiles'">{{'FOOTER.CATALOG.FABRICS'|translate}}</a>
            </div>
          </div>
          <div class="col-lg-4">
            <h4 class="text-white">{{'FOOTER.NAVIGATION.HEADER'|translate}}</h4>
            <div class="border-title-footer"></div>
            <div class="link-footer-container">
              <a [routerLink]="'/'">{{'FOOTER.NAVIGATION.HOME'|translate}}</a>
              <a [routerLink]="'/nouveautes'">{{'FOOTER.NAVIGATION.NEW-PRODUCTS'|translate}}</a>
              <a [routerLink]="'/actualites'">{{'FOOTER.NAVIGATION.NEWS'|translate}}</a>
              <a [routerLink]="'/pressbook'">{{'FOOTER.NAVIGATION.PRESS-BOOK'|translate}}</a>
              <a [routerLink]="'/partenaires'">{{'FOOTER.NAVIGATION.PARTNERS'|translate}}</a>
              <a [routerLink]="'/contact'">{{'FOOTER.NAVIGATION.CONTACT'|translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-3 text-white text-center text-lg-start">
        <h4>{{'FOOTER.CONTACT'|translate}}</h4>
        <div class="border-title-footer"></div>
        <ul class="list-unstyled text-start mt-3 text-center text-lg-start">
          <a [routerLink]="'tel:+33699259572'">06 99 25 95 72</a>
          <br/>
          <a [routerLink]="'tel:+33296850587'">02 96 85 05 87</a>
          <div class="mt-3">
            <li>9, rue du Petit Fort</li>
            <li>(Jerzual) 22100 Dinan</li>
            <li>France</li>
          </div>
          <div class="mt-3">
            <a [routerLink]="'mailto:claude@maisonbleulin.com'">claude@maisonbleulin.com</a>
          </div>
        </ul>
      </div>
    </div>
    <hr>
    <div>
      <div class="footer-divider"></div>
      <div class="row text-white">
        <div class="col-lg-6 footer-link">
          <a [routerLink]="'https://agence-11h10.fr'" target="_blank">{{'FOOTER.LINKS.11H10'|translate}}</a>
          <a [routerLink]="'/mentions-legales'">{{'FOOTER.LINKS.LEGAL-MENTIONS'|translate}}</a>
          <a [routerLink]="'/mentions-legales'">{{'FOOTER.LINKS.PRIVACY-POLICY'|translate}}</a>
          <a [routerLink]="'/cgv'">{{'FOOTER.LINKS.CGV'|translate}}</a>
        </div>
        <div class="col text-lg-end  text-center pb-3">
          <a [routerLink]="'https://www.instagram.com/maisonbleulin/'" target="_blank"><strong><span class="fa fa-instagram me-3"></span></strong></a>
          <a [routerLink]="'/contact'"><strong><span class="fa fa-envelope-o"></span></strong></a>
        </div>
      </div>
    </div>
  </div>
</footer>
