import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { User as UserModel } from 'src/app/_models/user';
import {TokenStorageService} from "./token-storage.service";

const API = `${environment.api}/`;
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token = this.tokenStorageService.getToken();

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }



  login(email: string, password: string): Observable<any> {
    return this.http.post(API + 'auth/login', {
      email,
      password
    }, httpOptions);
  }


  register(email: string, name: string, prenom: string, address: string, zip: string, country: string, city: string, phoneNumber: string, password: string): Observable<any> {

    return this.http.post(API + 'auth/register', {
      email,
      name,
      prenom,
      address,
      zip,
      country,
      city,
      phoneNumber,
      password,
    }, httpOptions);
  }

  getUserDetails(){
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(API + 'api/account', {headers: reqHeader});
  }

  updateUserDetails(user: any, id: any) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.patch(API + 'api/account/' + id,
      {
        name: user.nom,
        zip: user.zipCode,
        city: user.city,
        country: user.country,
        //state: "string",
        address: user.adresse,
        phoneNumber: user.tel
      },

      {headers: reqHeader});
  }
}
