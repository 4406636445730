<div class="intro text-center" [ngStyle]="{'background-image': 'url(' + image + ')'}">
  <div class="overlay"></div>
  <div class="text-bandeau">
    <h1>{{titre}}</h1>
    <h2>{{sousTitre}}
      <span *ngIf="!focus"> | {{titre}}</span>
      <span *ngIf="focus"> | {{focus}} </span>
    </h2>
  </div>
</div>
