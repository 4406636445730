<div class="commande-container">
  <mat-form-field appearance="standard">
    <mat-label>{{'FOOTER.NAVIGATION.SEARCH'|translate}}</mat-label>
    <input matInput placeholder="Recherche" (keyup)="applyFilter($event)" #input>
  </mat-form-field>
  <div class="mat-elevation-z2">
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="">ID</th>
        <td mat-cell *matCellDef="let row; let i = index">{{i + 1}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="">Status</th>
        <td mat-cell *matCellDef="let row">{{row.status|translate}}</td>
      </ng-container>
<!--
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="">Total</th>
        <td mat-cell *matCellDef="let row">{{row.total}} €</td>
      </ng-container>-->

      <ng-container matColumnDef="facture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="">{{'ACCOUNT.BON-DE-COMMANDE'|translate}}</th>
        <td mat-cell *matCellDef="let row"><a target="_blank" class="facture" (click)="getFacture(row.id)">#{{row.id}}</a></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>

      <tr *matNoDataRow>
        <td class="mat-cell no-result" colspan="4">Aucun resultat pour {{input.value}}</td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Nombre d'items par page"></mat-paginator>
  </div>
</div>
