<div class="login-container">
  <div class="login-form">
    <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="container-login">
        <div class="login">
          <h1 class="connexion-title">{{'LOGIN.TITLE' | translate}}</h1>
          <p class="bienvenue-text">{{'LOGIN.PITCH' | translate}}</p>
          <div class="input-container">
            <mat-form-field appearance="outline">
              <mat-label>{{'LOGIN.FORM.FIELDS.EMAIL.LABEL' | translate}}</mat-label>
              <input class="login" matInput type="text" name="username" [(ngModel)]="form.username" required
                     #username="ngModel">
              <mat-icon matSuffix>username</mat-icon>
              <mat-hint>
                <div class="alert alert-danger" role="alert" *ngIf="username.errors && f.submitted">
                  {{'LOGIN.FORM.FIELDS.EMAIL.ERROR' | translate}}
                </div>
              </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{'LOGIN.FORM.FIELDS.PASSWORD.LABEL' | translate}}</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" name="password" id="password" [(ngModel)]="form.password"
                     #password="ngModel"
                     required>
              <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-hint>
                <div style="color: red" role="alert" *ngIf="password.errors && f.submitted">
                  <div *ngIf="password.errors.required">{{'LOGIN.FORM.FIELDS.PASSWORD.ERROR' | translate}}</div>
                </div>
              </mat-hint>
            </mat-form-field>
            <div class="d-flex justify-content-between align-items-center">
              <button class="btn btn-primary shadow-none" type="submit">{{'LOGIN.FORM.BUTTONS.LOGIN' | translate}}</button>
              <a href="#" class="login-pass-forget">{{'LOGIN.FORM.BUTTONS.FORGOT-PASSWORD' | translate}}</a>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
              Login failed: {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
      <p class="pas-de-compte-text mt-2">{{'LOGIN.FORM.BUTTONS.NO-ACCOUNT' | translate}}<a href="/inscription"> {{'LOGIN.FORM.BUTTONS.REGISTER' | translate}}</a></p>


      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse" [fullScreen]="false">
        <p style="color: white"> Chargement... </p>
      </ngx-spinner>
    </form>
  </div>
</div>
