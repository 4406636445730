<div class="profil-informations-container">

  <h1>{{'PROFIL.TITLE' | translate}}</h1>
  <p class="gerez-profil">{{'PROFIL.PITCH' | translate}}</p>

  <div class="form-container">
    <form class="register-form" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="full-width input-container">
        <mat-form-field appearance="outline" class="full-width-3">
          <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.LABEL' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.LABEL' | translate}}"
                 name="nom" [(ngModel)]="form.nom" required minlength="3"
                 maxlength="20" #nom="ngModel" value="{{userDetails.name}}">
          <mat-error *ngIf="nom.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.ERROR' | translate}}</mat-error>
          <mat-error *ngIf="nom.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.MIN-LENGTH' | translate}}</mat-error>
          <mat-error *ngIf="nom.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.LASTNAME.MAX-LENGTH' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width-3">
          <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.LABEL' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.LABEL' | translate}}"
                 name="prenom" [(ngModel)]="form.prenom" required minlength="3"
                 maxlength="20" #prenom="ngModel" value="{{userDetails.prenom}}">
          <mat-error *ngIf="prenom.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.ERROR' | translate}}</mat-error>
          <mat-error *ngIf="prenom.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.MIN-LENGTH' | translate}}</mat-error>
          <mat-error *ngIf="prenom.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.FIRSTNAME.MAX-LENGTH' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width-3">
          <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.LABEL' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.LABEL' | translate}}"
                 name="tel" [(ngModel)]="form.tel" required minlength="10"
                 maxlength="10" #tel="ngModel" value="{{userDetails.phoneNumber}}">
          <mat-error *ngIf="tel.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.ERROR' | translate}}</mat-error>
          <mat-error *ngIf="tel.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.MIN-LENGTH' | translate}}</mat-error>
          <mat-error *ngIf="tel.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.PHONE-NUMBER.MAX-LENGTH' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div class="full-width input-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.LABEL' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.LABEL' | translate}}"
                 name="zipCode" [(ngModel)]="form.zipCode" required minlength="3"
                 maxlength="20" #zipCode="ngModel" value="{{userDetails.zip}}">
          <mat-error *ngIf="zipCode.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.ERROR' | translate}}</mat-error>
          <mat-error *ngIf="zipCode.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.MIN-LENGTH' | translate}}</mat-error>
          <mat-error *ngIf="zipCode.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ZIP-CODE.MAX-LENGTH' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.LABEL' | translate}}</mat-label>
          <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.LABEL' | translate}}"
                 name="country" [(ngModel)]="form.country" required minlength="3"
                 maxlength="20" #country="ngModel" value="{{userDetails.country}}">
          <mat-error *ngIf="country.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.ERROR' | translate}}</mat-error>
          <mat-error *ngIf="country.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.MIN-LENGTH' | translate}}</mat-error>
          <mat-error *ngIf="country.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.COUNTRY.MAX-LENGTH' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.LABEL' | translate}}</mat-label>
        <input matInput type="text" placeholder="{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.LABEL' | translate}}"
               name="adresse" [(ngModel)]="form.adresse" required minlength="10"
               maxlength="150" #adresse="ngModel" value="{{userDetails.address}}">
        <mat-error *ngIf="adresse.errors?.required">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.ERROR' | translate}}</mat-error>
        <mat-error *ngIf="adresse.errors?.minlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.MIN-LENGTH' | translate}}</mat-error>
        <mat-error *ngIf="adresse.errors?.maxlength">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.FIELDS.ADDRESS.MAX-LENGTH' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Email</mat-label>
        <input disabled matInput type="text" placeholder="Email"
               name="email" [(ngModel)]="form.email" required minlength="10"
               maxlength="150" #email="ngModel" value="{{userDetails.email}}">
      </mat-form-field>

      <button class="btn btn-primary">{{'PROFIL.PERSONAL-INFORMATIONS.FORM.BUTTONS.SAVE' | translate}}</button>
    </form>
  </div>
</div>
