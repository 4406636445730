import { Component, OnInit } from '@angular/core';
import {ProduitService} from "../../_services/produit.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import slugify from "slugify";

@Component({
  selector: 'app-news-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent implements OnInit {

  products: any = [];
  p: number = 1;
  category: any = [];
  slugCategory : any;
  slugSubCateg: any;
  mainCateg : any;
  subCateg : any;
  constructor(private http: HttpClient, public translate: TranslateService, private produitService : ProduitService, private route: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.catalogCateg();
    })

    this.catalogCateg();
  }

  getProductsByCategory(category: any | null) {
    this.produitService.getProducts(1, 9999, this.translate.currentLang).subscribe(
      data => {
        let produits: any = [];

        // @ts-ignore
         data.forEach((element: any) => {

           let label = slugify(element.category.parent.label).toLowerCase();
           let categ = slugify(this.slugCategory).toLowerCase();

           if (label == categ) {
            produits.push(element);
           }
         })
        this.products = produits;
      },
      err => {
        console.warn("err: ", err);
      }
    )
  }

  getSubCategories(category: any | null) {
    this.produitService.getAllCategory().subscribe(
      data => {
        let categ = ['brocante', 'mercerie', 'textiles', 'creations'];

        if(this.slugCategory == categ[0]){
          this.category = this.reOrder(data[0].enfant)
          this.mainCateg = data[0]
        }
        else if(this.slugCategory == categ[1]){
          this.category = this.reOrder(data[1].enfant)
          this.mainCateg = data[1]
        }
        else if(this.slugCategory == categ[2]){
          this.category = this.reOrder(data[2].enfant)
          this.mainCateg = data[2]
        }
        else if(this.slugCategory == categ[3]){
          this.category = this.reOrder(data[3].enfant)
          this.mainCateg = data[3]
        }
      },
      err => {
        console.warn("err: ", err);
      }
    )
  }

  reOrder(categ: any){
    categ.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    })

    return categ;
  }

  catalogCateg() {
    this.slugCategory = this.route.snapshot.paramMap.get('category');
    this.products = [];
    this.category = [];
    this.subCateg = null;
    this.mainCateg = null;
    this.getSubCategories(this.slugCategory);

    if (this.route.snapshot.paramMap.get('subcategory') != null) {
      let produits: any = [];
      this.slugSubCateg = this.route.snapshot.paramMap.get('subcategory');
      this.produitService.getCategoryBySlug(this.slugSubCateg).subscribe(
        data => {
          this.subCateg = data;
          // @ts-ignore
          data.products.forEach((element: any) => {
            console.log(element)
            this.produitService.simpleQuery(element).subscribe(
              data => {
                produits.push(data);
              })
          })
          this.products = produits;
        })
    } else {
      this.getProductsByCategory(this.slugCategory);
    }
  }
}
