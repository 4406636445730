<div class="text-center product">
  <a [routerLink]="'/catalogue/'+ product.category.parent.slug +'/'+product.category.slug + '/' + product.id" class="background-product" [style.background-image]='"url(" + S3 + product.thumbnails[0] +")"'>
    <div class="hover">
    </div>
    <app-loader *ngIf="displayLoading" class="loader"></app-loader>
  </a>
  <div class="button">
    <a [routerLink]="'/catalogue/'+ product.category.parent.slug +'/'+ product.category.slug + '/' + product.id" class="bg-green">
      <span class="fa fa-eye px-3"></span>
    </a>
    <a *ngIf="isLoggin"  data-toggle="modal" data-target="#exampleModal" class="bg-dark-green add-to-panier" (click)="openModal()">
      <span class="fa fa-shopping-basket px-3"></span>
    </a>
  </div>
  <p class="product-title">{{ product.label }}</p>
  <div class="product-divider"></div>
  <p class="product-price"> {{ (product.price / 100) | currency: 'EUR':'symbol':undefined:'fr-FR' }} </p>
</div>
