import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

const API = `${environment.api}/api/carousels`;

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  products: any = [];

  constructor(private http: HttpClient) { }

  getSliders(lang: string) {
    const headers = {
      'Accept-Language': lang
    };

    return this.http.get(API, {headers});
  }

}
