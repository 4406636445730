import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

const API = `${environment.api}/api/pressbooks`;

@Injectable({
  providedIn: 'root'
})
export class PressbookService {


  constructor(private http: HttpClient, private translate: TranslateService) { }

  getPressbook() {
    let headers = new HttpHeaders().set('Accept-Language', this.translate.currentLang);
    return this.http.get(API);
  }

  getPressbookById(id: number) {
    let headers = new HttpHeaders().set('Accept-Language', this.translate.currentLang);
    return this.http.get(`${API}/${id}`, {headers: headers});
  }
}
