<div [style.background-image]="'url(' + S3 + slider.image +')'" class="item text-center">
  <div class="bg-overlay"></div>
  <a class="row item-text">
    <div class="col-lg-1 d-none d-lg-flex justify-content-center align-items-center">
      <a class="prev" (click)="prev()">
        <i class="fa-solid fa-circle-chevron-left"></i>
      </a>
    </div>
    <a *ngIf="slider.lien"  [routerLink]="'/' + slider.lien" class="col-lg-10">
      <div class="carousel-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slider.content)">

      </div>
      <p class="text-white">{{slider.lien}}</p>
    </a>
    <div *ngIf="!slider.lien" class="col-lg-10">
      <div class="carousel-title" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slider.content)">

      </div>
      <p class="text-white">{{slider.lien}}</p>
    </div>
    <div class="col-lg-1 d-none d-lg-flex justify-content-center align-items-center">
      <a class="next" (click)="next()">
        <i class="fa-solid fa-circle-chevron-right"></i>
      </a>
    </div>
  </a>
</div>
