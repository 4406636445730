import {Component, OnInit, SimpleChange, ViewChild} from '@angular/core';
import {ProduitService} from "../../_services/produit.service";
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  products: any = [];
  p: number = 1;
  keyword = 'description';
  value = '';
  searchForm: any ;

  constructor(private produitService: ProduitService, private formBuilder: FormBuilder, public translate: TranslateService) {
    this.searchForm = this.formBuilder.group({
      search: '',
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.products = [];
      this.getProducts();
    })

    this.getProducts();
  }

  getProducts(){
    this.produitService.getProducts(1, 999, this.translate.currentLang).subscribe(
      (data) => {
        this.products = data;
      })
  }
}
