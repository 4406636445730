import {Product} from './product';

export class CartItem {
  id: number;
  productId: number;
  productName: string;
  qty: number;
  price: number;
  image: string;
  quantityInStock : number;

  constructor(id: number, idItem: number, nameItem: string, priceItem: number , qty = 1, image : string = '', quantityInStock : number) {
    this.id = id;
    this.productId = idItem;
    this.productName = nameItem;
    this.price = priceItem;
    this.qty = qty;
    this.image = image;
    this.quantityInStock = quantityInStock;
  }
}
