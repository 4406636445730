import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {TranslateService} from "@ngx-translate/core";


const API = `${environment.api}/api/`;
const BASE = `${environment.api}`;

@Injectable({
  providedIn: 'root'
})
export class ProduitService {

  products: any = [];

  constructor(private http: HttpClient, private translate: TranslateService) { }

  getRandomProducts(limit: number, language: string)
  {
    let headers = new HttpHeaders().set('Accept-Language', language);
    let params = new HttpParams().append('limit',limit)
    return this.http.get(API+'random-products',{headers,params})
  }
  getProducts(page: number, item: number, language: string) {
    let headers = new HttpHeaders().set('Accept-Language', language);
    return this.http.get(API + 'products?page=' + page + '&items=' + item, { headers });
  }

  getRelatedProducts(page: number, item: number, language: string, category: string) {
    let headers = new HttpHeaders().set('Accept-Language', language);
    return this.http.get(API + 'products?page=' + page + '&items=' + item + '&category.slug=' + category, { headers });
  }

  getOneProductById(productId: number, language: string) {
    let headers = new HttpHeaders().set('Accept-Language', language);
    return this.http.get(API + 'products/' + productId, { headers });
  }

  getCategories(category: any | null){
    return this.http.get(API + 'categories/' + category, {headers: {'Accept-Language': this.translate.currentLang}});
  }

  getAllCategory() {
    return this.http.get<any[]>(API + 'categories_by_parent', {
      headers: {'Accept-Language': this.translate.currentLang}
    });
  }

  getCategoryBySlug(brocante: string) {
    return this.http.get<any[]>(API + 'categories/' + brocante);
  }

  simpleQuery(query: string) {
    return this.http.get<any[]>(BASE + query, {
      headers: {'Accept-Language': this.translate.currentLang}
    });
  }
}
