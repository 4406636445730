import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './pages/cart/cart.component';
import { CatalogsComponent } from './pages/catalogs/catalogs.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsDescribeComponent } from './pages/news-describe/news-describe.component';
import { NewsComponent } from './pages/news/news.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PressbookComponent } from './pages/pressbook/pressbook.component';
import {ProductDescribeComponent} from "./pages/product-describe/product-describe.component";
import {NewsCatalogComponent} from "./pages/news-catalog/news-catalog.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {PartenairesComponent} from "./pages/partenaires/partenaires.component";
import {ConnexionComponent} from "./pages/connexion/connexion.component";
import {QuiSuisJeComponent} from "./pages/qui-suis-je/qui-suis-je.component";
import {InscriptionComponent} from "./pages/inscription/inscription.component";
import {SearchComponent} from "./structure/search/search.component";
import {ProfilComponent} from "./pages/profil/profil.component";
import {CgvComponent} from "./pages/cgv/cgv.component";
import {MentionsLegalesComponent} from "./pages/mentions-legales/mentions-legales.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'connexion', component: ConnexionComponent},
  {path: 'actualites', component: NewsComponent},
  {path: 'catalogue/:category', component: CatalogsComponent, runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'},
  {path: 'catalogue/:category/:subcategory', component: CatalogsComponent, runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'},
  {path: 'nouveautes', component: NewsCatalogComponent},
  {path: 'panier', component: CartComponent},
  {path: 'pressbook', component: PressbookComponent},
  {path: 'actualites/:slug', component: NewsDescribeComponent, runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'},
  {path: 'catalogue/:category/:subcategory/:slug', component: ProductDescribeComponent, runGuardsAndResolvers: 'pathParamsOrQueryParamsChange'},
  {path: 'contact', component: ContactComponent},
  {path: 'profil', component: ProfilComponent},
  {path: 'partenaires', component: PartenairesComponent},
  {path: 'cgv', component: CgvComponent},
  {path: 'mentions-legales', component: MentionsLegalesComponent},
  {path: 'about', component: QuiSuisJeComponent},
  {path: 'inscription', component: InscriptionComponent},
  {path: 'recherche', component: SearchComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation: 'reload',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
