import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";

const API = `${environment.api}/api/news`;

@Injectable({
  providedIn: 'root'
})
export class ActualitesService {

  constructor(private http: HttpClient) { }

  getActualites(language: string) {
    let headers = new HttpHeaders().set('Accept-Language', language);
    return this.http.get(API, { headers });
  }

  getActualite(id: number, language: string) {
    let headers = new HttpHeaders().set('Accept-Language', language);
    return this.http.get(API + '/' + id, { headers });
  }

}
