import { Component, OnInit } from '@angular/core';
import {ActualitesService} from "../../_services/actualites.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-news-describe',
  templateUrl: './news-describe.component.html',
  styleUrls: ['./news-describe.component.scss']
})
export class NewsDescribeComponent implements OnInit {

  actualite: any;
  slug: any;
  S3 = `${environment.s3}`;

  constructor(private actualiteService: ActualitesService, private route: ActivatedRoute, private router: Router, private translate: TranslateService) {

  }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.getNewsById(this.slug);
  }

  getNewsById(id: number) {

    this.actualiteService.getActualite(id, this.translate.currentLang).subscribe(
      (data) => {
        this.actualite = data;
      })
  }

}
