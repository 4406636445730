import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  @Input() dropdown: any;
  @Input() image : string | undefined;

  ngOnInit(): void {
  }

  slugify(str: string) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to   = "aaaaeeeeiiiioooouuuunc------";

    return str.split('').map((letter, i) => {
      let index = from.indexOf(letter);
      if (index !== -1) {
        return to[index];
      }
      return letter;
    }).join('').replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
  }
}
