import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from "../../_services/token-storage.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {AuthService} from "../../_services/auth.service";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  token = this.tokenStorageService.getToken();
  user = this.tokenStorageService.getUser();

  profil: boolean = true;
  commande: boolean = false;


  constructor(private tokenStorageService: TokenStorageService, private router: Router, private authSerice: AuthService) { }

  ngOnInit(): void {
    if(!this.token) {
      this.router.navigate(['/connexion']);
    }

  }

  changeProfil(link: string) {
    if(link == "profil") {
      this.profil = true;
      this.commande = false;
    } else {
      this.profil = false;
      this.commande = true;
    }
  }


}
