<app-intro [image]="'/assets/images/bandeau-titre/bandeau-produits.png'" [titre]="labelProduit"
           [sousTitre]="productConcat"></app-intro>
<div class="container my-5 text-center text-lg-start">
  <div class="row d-flex">
    <div class="col-lg-4">
      <img id="main-image" class="img-fluid" src="{{ S3 + produit?.thumbnails[0] }}">
      <div class="extra-img">
        <img (click)="changeMainImage(img)" *ngFor="let img of produit?.thumbnails;" src="{{ S3 + img }}">
      </div>
    </div>
    <div class="col-lg-8">
      <p class="mb-1"><small>{{'PRODUCT.REFERENCE'|translate}} : {{produit?.ref}}</small>
        <span *ngIf="isNew" class=" ms-3 badge badge-secondary bg-dark-green">{{'PRODUCT.NEW'|translate}}</span>
        <span *ngIf="produit?.quantityInStock >= 1"
              class="badge badge-instock bg-dark-green">En stock: ({{produit?.quantityInStock}})</span>
        <span *ngIf="produit?.quantityInStock <= 0" class="badge badge-not-instock">Stock epuisé</span>
      </p>
      <h1 class="text-dark-green">{{ produit?.label }}</h1>
      <div class=" mx-auto  mx-lg-0 border w-5 border-dark-green mb-3"></div>
      <p class="text-dark-green f-24">{{ (produit?.price / 100) | currency: 'EUR':'symbol':undefined:'fr-FR' }}</p>
      <p>{{ produit?.pitch }}</p>

      <div class="stock-produit-wrapper">

      </div>
      <div *ngIf="produit?.quantityInStock >= 1" class="nombre-produit">
        <app-number-field #qty [min]="1" [max]="produit?.quantityInStock"></app-number-field>
        <a class="btn btn-primary" (click)="addToCart(qty)">{{'PRODUCT.ADD-TO-CART'|translate}}</a>
      </div>
      <div *ngIf="produit?.quantityInStock <= 0" class="stock-epuisé">
        <p>Malheureusement, ce produit est victime de son succès.</p>
      </div>
    </div>
  </div>
  <h2 class="mt-4"><u>{{'PRODUCT.DESCRIPTION'|translate}}</u></h2>
  <p>{{ produit?.description }}</p>
  <h2 class="mt-4"><u>{{'PRODUCT.SHARE'|translate}}</u></h2>
  <div *ngIf="shareUrl">
    <share-buttons [url]="shareUrl" [show]=3></share-buttons>
  </div>
  <img class="img-flower" src="/assets/images/intro/flower.png">

  <h2 class="product-similar-title"><u>{{products.length}} {{'PRODUCT.OTHER-IN-CATEGORY'|translate}}</u> :</h2>
  <div class="product-similar">
    <div *ngFor="let p of products | paginate: { itemsPerPage: 6, currentPage: p }" class="product-wrapper">
      <app-product [product]="p"></app-product>
    </div>
  </div>
  <pagination-controls (pageChange)="p = $event" previousLabel="" nextLabel=""
                       class="float-right"></pagination-controls>

  <app-newsletter></app-newsletter>
</div>
