<div class="panier-items" id="cart-{{cartItem.id}}">
  <div class="items">
    <div class="items-body">
      <div class="title-desc">
        <a class="cart-item-a" [routerLink]="'/catalogs/' + cartItem.productId">
          <img class="items-image" src="{{S3 + cartItem.image}}">
        </a>
        <div class="title-items">
          <p class="items-title">{{cartItem.productName}}</p>
          <p class="items-stock">En stock: {{cartItem.quantityInStock}}</p>
          <!--          <span class="items-category">{{cartItem.productName}}</span>-->
        </div>
      </div>
      <div class="price-desc">
        <p class="items-price">{{(cartItem.price / 100) | number}}€</p>
      </div>
      <div class="qty-desc">
        <div class="num-block skin-2">
          <div class="num-in">
            <span (click)="removeItemFromcart(cartItem.productId, 1)" class="minus dis"></span>
            <input (keydown)="manageNbQty(customQty.value)" (keyup)="manageNbQty(customQty.value)" #customQty
                   type="text"
                   class="in-num"
                   [value]="cartItem.qty"
            >
            <span (click)="addItemToCart(1)" class="plus"></span>
          </div>
        </div>

        <div class="handle-missed-stock" *ngIf="cartItem.stockNotAvailable">
          <span matTooltip="{{'OUT-OF-STOCK'|translate}}">
            <i class="fa-solid fa-circle-exclamation"></i>
          </span>
        </div>
      </div>

      <div class="total-desc">
        <p class="items-total">{{(cartItem.qty * (cartItem.price / 100)) | number}}€</p>
      </div>

      <div class="delete delete-desc">
        <span class="items-delete" (click)="removeItemFromcart(cartItem.productId, cartItem.qty, true)">
          <i class="fas fa-times p-2"></i>
        </span>
      </div>
    </div>
  </div>
</div>
