<app-intro [image]="'/assets/images/bandeau-titre/header-cat-actu.jpg'" [titre]="'Actualites'"
           [sousTitre]="'Accueil | Actualites'" [focus]="actualite?.title"></app-intro>

<div class="container">
  <div class="row align-items-start text-center text-lg-start">
    <div>
      <h3 class="f-24 mt-5">
        {{actualite?.title}}
      </h3>
    </div>
    <div class="date my-4">
      <p>{{actualite?.dateDebut | date}}</p>
    </div>
    <div class="row align-items-start">
      <div class="col-lg-4">
        <img class="img-news" src="{{S3 + actualite?.thumbnail}}" alt="{{actualite?.thumbnailCaption}}">
      </div>
      <div class="col-lg-8" [innerHTML]="actualite?.content">
      </div>
    </div>
    <div class="text-center mb-5">
      <a [routerLink]="'/actualites'" class="btn btn-primary">{{'NEWS.SEE-ALL'|translate}}</a>
    </div>
  </div>
</div>
<app-newsletter></app-newsletter>
