<app-intro [image]="'/assets/images/bandeau-titre/bandeau-panier.png'" [titre]="'FOOTER.NAVIGATION.CART'|translate"
           [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>

<div *ngIf="isLoggedIn" class="panier-wrapper text-center text-lg-end">
  <div class="panier">
    <button type="button" (click)="validateOrder()" class="btn btn-primary mt-5">{{"CART.ORDER.CONFIRM"|translate}}</button>
    <div class="text-center mt-3">
      <div class="panier-container">
        <div class="panier-header">
          <div class="panier-header__produit">{{"CART.HEADERS.PRODUCTS"|translate}}</div>
          <div class="panier-header__prix">{{"CART.HEADERS.PRICE"|translate}}</div>
          <div class="panier-header__qty">{{"CART.HEADERS.QUANTITY"|translate}}</div>
          <div class="panier-header__total">{{"CART.HEADERS.TOTAL"|translate}}</div>
        </div>

        <div class="panier-body">
          <div *ngIf="cartItems.length === 0">
            <p>{{"CART.EMPTY"|translate}}</p>
          </div>

          <div class="panier-scroll" *ngIf="cartItems.length > 0">
            <div *ngFor="let item of cartItems">
              <app-cart-item [cartItem]="item"></app-cart-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="total-container">
        <p class="text-dark">{{"CART.ORDER.TOTAL"|translate}} <span class="ms-5">{{(cartTotal / 100) | number}} €</span></p>
        <a href="/" class="btn btn-outline-primary">{{"CART.KEEP-SHOPPING"|translate}}</a>
        <button (click)="validateOrder()" type="button" class="btn btn-primary">{{"CART.ORDER.CONFIRM"|translate}}</button>
      </div>
    </div>
    <p class="text-start mt-3">{{'CART.LEGALS' | translate}}</p>
  </div>
</div>
<div *ngIf="!isLoggedIn">
  <p class="is-not-logged">{{'CART.VISUALISE' | translate}} <a href="/connexion">{{'LOGIN.FORM.BUTTONS.LOGIN' | translate }}</a></p>
</div>
<app-newsletter></app-newsletter>
