<app-intro [image]="'/assets/images/bandeau-titre/bandeau-produits.png'" [titre]="'FOOTER.NAVIGATION.WHO-AM-I'|translate"
           [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>

<section class="about-section">
  <div class="about-container" *ngIf="lang === 'fr'">
    <img class="flower-about" src="/assets/images/cookie.png"/>
    <h1>Qui suis-je ?</h1>
    <p>
      <span>11 juillet 1998 :</span> Naissance de Fleur de lin, boutique de mercerie ancienne à Dinan
    </p>
    <img src="./assets/images/qui-suis-je/1.jpg">

    <p><span>14 septembre 2000 :</span> La boutique change de nom et devient Fleur de lin et Bouton d'or</p>

    <p><span>22 novembre 2001 :</span> début participation aux grands salons parisiens, 1ère participation à Créations et Savoir-faire Porte de Versailles à Paris (4 éditions)</p>

    <p><span>Mars 2003 :</span> 1er site internet Fleur de lin et Bouton d'or</p>

    <p><span>Avril 2004 :</span> Participation à la 1ère édition Aiguille en Fête à la Halle de la Villette à Paris (5 éditions)</p>

    <p><span>9 mars 2005 :</span> début de l'activité artisanale (créations de boutons, cartes à l'aide d'articles anciens)</p>

    <p><span>27 février 2006 :</span> Catalogue de vente en ligne sur le nouveau site internet</p>

    <p><span>Octobre 2006 :</span> participation à la 1ère édition Salon Marie-Claire Idées au Carroussel du Louvre à Paris</p>

    <p><span>Janvier 2008 :</span> création du blog de ma boutique Fleur de lin et Bouton d'or</p>

    <p><span>1er au 5 mai 2008 :</span> Expo des 10 ans de ma boutique de mercerie ancienne Fleur de lin et Bouton d'or intitulée "Fleur de lin tisse sa toile"

      à la Maison du Gouverneur à Dinan avec la mise sur pied et l'organisation d'un concours textile, d'une exposition gratuite ouverte à toutes et tous

      et de stages textiles animés par 10 professionnels passionnés (3500 visiteurs).


      <img src="./assets/images/qui-suis-je/2.jpg">

      <br/><br/>De <span>2003 à 2011,</span> participation à de nombreux salons dont :

      <br/>
      <br/>- Le <span>Carrefour Européen du Patchwork en Alsace</span> (3 éditions)

      <br/>- Le <span>Festival du Lin en Haute Normandie</span> (8 éditions)

      <br/>- Le <span>salon Pour l'Amour du Fil à Nantes</span> (1ère édition)

      <br/>- Le <span>Festival de broderie de Compiègne</span> (3 éditions)

      <br/>- Le <span>salon de broderie de Moncoutant</span> (3 éditions)

      <br/>- Le <span>salon de broderie de Flers</span> (4 éditions)

      <br/>- Le <span>salon de broderie de Vernon dans l'Eure</span> (3 éditions)

      <br/>- Le <span>Printemps des brodeuses à Criel sur Mer en Haute Normandie</span> (3 éditions)

      <br/>- Le <span>salon Fous d'histoire à Pontoise</span> (4 éditions)

      <br/>- Le <span>salon Abilmente à Vicenza en Italie</span> (3 éditions)

      <br/>- Le <span>salon textile Italia Invita à Parme (Italie)</span> (3 éditions)

      <br/>- Le <span>salon Country Life à Vérone (Italie)</span> (1ère édition)

      <br/>- <span>Exposition-vente à Tokyo (Japon)</span>
      <br/><br/>
      ainsi que de nombreux autres salons souvent organisés de main de maître par de dynamiques boutiques et clubs de broderie, patchwork, travaux manuels

      de nombreuses villes et communes rurales en France (Coudray-Macouard, Manthelan, Perrusson, Chateauroux, Rots, le Teilleul, Flers, Lesneven, Pexiora,
      Cabourg, Nans sous ste Anne, Linas, Honfleur, Pontoise, Le Puy en Velay, Cerisy belle étoile, Pont-Ramond, Champigny sur marne, Brunoy, Plessix-Robinson,

      Val de Sâane, Nantes, Thouars, Le Mans, Wissous, Bazouges sur le Loir ..) et en Grande-Bretagne, Belgique, Italie.
    </p>

    <p><span>31 décembre 2011 :</span> fin de l'aventure de mercerie ancienne</p>

    <img src="./assets/images/qui-suis-je/3.jpg">

    <p><span>22 juillet 2012 :</span> ouverture de Maison Bleu Lin, salon de thé aux spécialités britanniques (scones et clotted cream de Cornouaille anglaise) et bretonnes (produits locaux biologiques),

      le tout dans le décor préservé de la boutique de mercerie ancienne.</p>

    <img src="./assets/images/qui-suis-je/4.jpg">

    <p><span>Juin 2013 :</span> Mise sur pied et organisation avec quatre autres boutiques partenaires d'un concours textile Tea cosy in Dinan pour faire le lien entre la mercerie ancienne Fleur de Lin et Bouton d'or

      et la nouvelle activité de salon de thé de Maison Bleu Lin. Exposition des oeuvres confectionnées dans les différents lieux.</p>

    <p><span>16 septembre 2016 :</span> pour diverses raisons, fin du salon de thé, Maison Bleu Lin retrouve ses premières amours textiles et de brocante avec notamment le lin artisanal italien,
      et une belle sélection d'épicerie fine et développe ses créations artisanales textiles faites à Dinan à partir de matières premières anciennes et recyclées afin de réduire l'impact environnemental.</p>

      Le tout est exposé dans une ambiance de charme à la décoration soignée et renouvelée au gré des saisons, des trouvailles et des bouquets du moment.

    <img src="./assets/images/qui-suis-je/5.jpg">

    <p><span>14 février 2017 :</span> nouveau site internet de Maison Bleu Lin</p>

    <p>L'année <span>2017</span> a été marquée par plusieurs événements (dont le dégât des eaux dans la maison et la boutique suite aux violents orages de printemps) qui ont entravé la bonne gestion de la boutique et du site.</p>

    <p><span>2017</span> s'achève avec une toute nouvelle carte de la boutique qui fait la part belle à l'esthétique.</p>

    <img src="./assets/images/qui-suis-je/6.jpg">

    <p><span>2018 :</span> l'année des 20 ans de la boutique avec 20 rendez-vous en France et à l'étranger, 20 jours anniversaire...et un torchon commémoratif en édition limitée tissé sur métier jacquard du 19ème siècle.</p>

    <p><span>2019 :</span> ouverture du compte Instagram</p>

    <p><span>2022 :</span> nouveau site de vente en ligne réalisé par Agence 11h10</p>

    <p><span>2023 :</span> la boutique fête ses 25 ans. Concours textile auquel participent 56 personnes ayant 9 mois pour réaliser un ouvrage avec les 25 anciennes matières premières fournies. Expo dans la boutique.</p>

    <img src="./assets/images/qui-suis-je/7.jpg">
    <br/>
      Maison Bleu Lin participera à trois salons textiles en Angleterre et en France.
  </div>
  <div class="about-container" *ngIf="lang === 'en'">
    <img class="flower-about" src="/assets/images/cookie.png"/>
    <h1>Who-am i ?</h1>
    <p>
      <span>11th july 1998 :</span> Day one for Fleur de lin, antique haberdashery boutique in Dinan
    </p>

    <img src="./assets/images/qui-suis-je/1.jpg">

    <p><span>14th september 2000 :</span> The boutique name becomes Fleur de lin et Bouton d'or</p>

    <p><span>22nd november 2001 :</span> beginning to participate to Paris major shows, 1st participation to Créations et Savoir-faire Porte de Versailles à Paris (4 editions)</p>

    <p><span>March 2003 :</span> 1st online Fleur de lin et Bouton d'or website</p>

    <p><span>April 2004 :</span> Participation to the first edition of Aiguille en Fête in la Villette Paris hall (5 editions)</p>

    <p><span>9th March 2005 :</span> Beginning of the artisan activity (buttons, cards created with antique articles)</p>

    <p><span>27th february 2006 :</span> Online catalog on new website with hundreds of articles & weekly multilingual newsletter</p>

    <p><span>October 2006 :</span> Participation to the first edition of Marie-Claire Idées fair in Paris Le Louvre Carroussel</p>

    <p><span>January 2008 :</span> Fleur de lin et Bouton d'or Blog</p>

    <p><span>1st to 5th may 2008 :</span> Fleur de lin et Bouton d'or antique haberdashery boutique 10th anniversary exhibition called "Fleur de lin tisse sa toile"

      in the Maison du Gouverneur (let by Dinan town council) in Dinan with the setting up and the organization of a textile contest (40 customers),
      a free exhibition opened to the public and various workshops by 10 keen and passionned textile professionnals (3500 visitors)

      <img src="./assets/images/qui-suis-je/2.jpg">

      <br/><br/><span>From 2003 to 2011 :</span> Participation to many fairs :

      <br/>
      <br/><span>- Carrefour Européen du Patchwork in Alsace</span> (3 editions)

      <br/><span>- Festival du Lin en Haute Normandie</span> (8 editions)

      <br/><span>- Pour l'Amour du Fil in Nantes</span> (1st edition)

      <br/><span>- Embroidery festival in Compiègne</span> (3 editions)

      <br/><span>- Embroidery fair in Moncoutant</span> (3 editions)

      <br/><span>- Embroidery fair in Flers</span> (4 editions)

      <br/><span>- Embroidery fair in Vernon in Eure</span> (3 editions)

      <br/><span>- Printemps des brodeuses in Criel sur Mer in Normandy</span> (3 editions)

      <br/><span>- Fous d'Histoire re enactment fair in Pontoise</span> (4 editions)

      <br/><span>- Abilmente crafts fair in Vicenza in Italy </span> (3 editions)

      <br/><span>- Italia Invita textile fair in Parma every two years (Italy)</span> (3 editions)

      <br/><span>- Country Life fair in Verona (italy)</span> (1st edition)

      <br/><span>-Exhibition in Tokyo (Japan)</span>
      <br/><br/>
      plus many other shows often organized by dynamic shop owners or embroidery and quilting clubs from many towns and rural communes in France
      (Coudray-Macouard, Manthelan, Perrusson, Chateauroux, Rots, le Teilleul, Flers, Lesneven, Pexiora, Cabourg, Nans sous ste Anne, Linas, Honfleur,

      Pontoise, Le Puy en Velay, Cerisy belle étoile, Pont-Ramond, Champigny sur marne, Brunoy, Plessix-Robinson, Val de Sâane, Nantes, Thouars,
      Le Mans, Wissous, Bazouges sur le Loir...) and Belgium, Great-Britain and Italy.
    </p>

    <p><span>31st december 2011 :</span> End of the antique haberdashery adventure</p>

    <img src="./assets/images/qui-suis-je/3.jpg">

    <p><span>22nd july 2012 :</span> Maison Bleu Lin opens, tea room with its many english specialities scones & Cornish clotted cream and
      breton ones (local organic products ..), in unique settings of antique haberdashery decor.</p>

    <img src="./assets/images/qui-suis-je/4.jpg">

    <p><span>June 2013 :</span>Setting up and organization with four other partner shops, of a textile contest Tea cosy in Dinan to make the link between Fleur de lin et Bouton d'or antique haberdashery activity and Maison Bleu Lin new tea room activity. Exhibition of the works made in the various shops & buying vouchers for best works after public voting.</p>

    <p><span>16th september 2016 :</span>end of the catering activity (in spite of the excellent reputation of my tea room)...Maison Bleu Lin retrieved its first textile & antiques passion with italian artisan linens, a little brocante & fine food selection & developped its artisan textile creations made in Dinan from antique and recycled materials to reduce the environmental impact by selling articles made from preexisting goods, all displayed in the charming ambiance of my boutique and its ever changing decor.</p>

    <img src="./assets/images/qui-suis-je/5.jpg">

    <p><span>2015 to 2019 :</span> exhibiting at several Christmas fairs in England (Petworth, Hampton Court castle & gardens, Cowdray estate, Parham house & gardens)

      and summer fairs organized by Lucy & team from The Country Brocante</p>

    <p><span>14th february 2017 :</span> New and more interactive Maison Bleu Lin online website</p>

    <p>The year <span>2017</span>  has been marked by several events, out of which the water flooding in the house and the boutique after violent spring thunderstorms,
      which disrupted my boutique & website good management.</p>

    <p><span>2017</span> New boutique card with a great aesthetic aspect.</p>

    <img src="./assets/images/qui-suis-je/6.jpg">

    <p><span>2018 :</span> My boutique twentieth anniversary with 20 textiles fairs in France and England, 20 days anniversary and a limited edition woven tea towel</p>

    <p><span>2019 :</span> Opening of Maison Bleu Lin Instagram account</p>

    <p><span>2022 :</span> New website with online catalog made by Agence 11h10</p>

    <p><span>2023 :</span>
      shop 25th anniversary (textile contest with 56 people taking part & having 9 months to make their own creation to be displayed in the shop)
    </p>

    <img src="./assets/images/qui-suis-je/7.jpg">

    <p><br/>Maison Bleu Lin will attend three textile fairs in England and France.</p>

  </div>
</section>
