<div class="container p-3" *ngIf="lang === 'en'">
  <h1>Legal Notice : </h1>

  <p class="legals-text p-3">
    <span>Legal notice</span>


    <br/><br/>The MAISON BLEU LIN website is the property of the company MAISON BLEU LIN, a sole proprietorship of Claude LE GUEN, whose headquarters are located at
    <br/><br/>9 Rue du Petit Fort

    <br/><br/>22100 DINAN

    <br/><br/>FRANCE

    <br/><br/>RCS ST MALO 419 939 715

    <br/><br/>VAT not applicable (article 293 B of the CGI)

    <br/><br/>SIRET N° 419 939 715 00019

    <br/><br/><span>Publishing manager: MAISON BLEU LIN</span>

    <br/><br/>The graphic design, IT development and hosting were carried out / are provided by the company Agence 11h10, limited company owned by Sylvain Guéné, whose registered office is located at Parc d'Activités Beaujardin, bâtiment Rhedae, 35410 CHATEAUGIRON, registered with the Rennes Commerce Office under the number RCS Rennes B 842 045 452 eurl with a capital of 3000 euros RCS RENNES - Siret N°49116059400017

    <br/><br/><span>Confidentiality - Personal data</span>
    <br/>All personal data collected on the site are treated with the strictest confidentiality. We remind you that you have the right to access, modify, rectify and delete data concerning you (art.34 of the French Data Protection Act of 6 January 1978). To exercise this right, please contact
    <br/><br/>MAISON BLEU LIN
    <br/>9 Rue du Petit Fort
    <br/>22100 DINAN
    <br/>FRANCE

    <br/><br/><span>Responsibility</span>
    <br/>MAISON BLEU LIN has only an obligation of means for all stages and functions of the site. MAISON BLEU LIN cannot be held responsible for any inconvenience or damage inherent to the use of the Internet network, including a break in service, external intrusion or the presence of computer viruses, or any event qualified as force majeure, according to case law.
    Intellectual property
    All elements of the MAISON BLEU LIN website, including downloadable documents, are protected by copyright, trademarks or patents. They are the exclusive property of MAISON BLEU LIN and / or these authors. The personal creations of Claude Le Guen are sold under the registered trademark BLEU LIN ET ROUGE GRIOTTE. Consequently, you can not in any way, reproduce, represent, distribute, modify, concede all or part of any of the elements reproduced on the site and all or part of the site without the prior and express consent of the company MAISON BLEU LIN and its rights.

    <br/><br/><span>Duration</span>
    <br/>These conditions apply for the duration of the services offered on the MAISON BLEU LIN website.

    <br/><br/><span>Applicable law and jurisdiction</span>
    These conditions are subject to French law. In case of dispute, and in the absence of an amicable agreement between the parties, the competent French courts will have jurisdiction.

    <br/><br/><span>Photographic credits: MAISON BLEU LIN and Rights holders.</span>

    <br/><br/><span>CNIL recommendations</span>

    <br/><span>I - What is a cookie ?</span>
    <br/>A cookie is a text file saved on your computer or mobile device and retrieved during your subsequent visits.

    <br/><br/><span>II - Legal obligations</span>
    <br/>The Internet user who visits the publisher's site must be informed of the purpose of the cookies, the publisher must obtain his consent and must provide Internet users with a means of refusing them. This consent is valid for 13 months. The obligation to obtain consent applies to website managers, application publishers, advertising agencies, publishers of audience measurement solutions and social networks. Some cookies require prior information and a request for consent from Internet users, such as cookies related to advertising operations and audience measurement cookies.

    <br/><br/><span>III - Display of an information banner</span>
    <br/>This banner informs Internet users about the precise purposes of the cookies used, the possibility of opposing these cookies and changing the parameters by placing a link on the banner. The "learn more" link allows the user to set the parameters of the cookies, accept or refuse them. This link should clearly and concisely inform the user what a cookie is.
    <br/><br/><span>IV - Webanalytics solutions</span>
    <br/>Piwik and Google Analytics are audience measurement tools that allow us to obtain information about visitors' browsing. These tools are exempt from consent but must respect certain standards. The cookie opposition mechanism must be easily accessible, the cookies deposited are only used to produce anonymous statistics, the cookies must not allow the user's browsing on other websites to be tracked, the cookies allowing the tracking of users and IP addresses must not be kept for more than 13 months from the last visit.

    <br/><br/><span>V - Social buttons</span>
    <br/>Share Privacy is a tool that allows you to integrate the buttons of the main social platforms without sending cookies before obtaining prior consent from the user. Social buttons are social plug-ins that allow web designers to easily add features to their web pages that make it easier to share the content of their sites on the different social platforms. They are tools that require prior consent from the user.

    <br/><br/><span>Data processing - (In compliance with the RGPD law)</span>
    <br/>RGPD standards - LAW n° 2018-493 of 20 June 2018 on the protection of personal data

    <br/><br/>MAISON BLEU LIN attaches great importance to the protection of your personal data.
    <br/>In order to follow this ethic, we suggest that you read our charter which completes the current terms of use of the Website as well as our legal notice.
    <br/>If you do not agree with these terms, you are free not to browse MAISON BLEU LIN and not to provide us with any personal data.

    <br/><br/><span>WHAT DOES THIS PRIVACY POLICY APPLY TO ?</span>
    This privacy policy applies to the entire website, digital applications and materials, and any other online initiatives such as contests or sweepstakes owned by the site editor that collect personal data. This policy does not apply to third party websites using our trade name for promotional purposes, including those that may be mentioned on our medium via a web link such as "Partners" websites.

    <br/><br/><span>WHAT DATA IS COLLECTED ?</span>
    <br/>With your prior consent, when you use our Media we may collect and process some or all of the following data:
    <br/> First name and surname;
    <br/>> Email address;
    <br/>> Telephone number;
    <br/>> Department;
    <br/>> Any particular request you may make to us (mainly for archiving purposes) such as appraisals and estimates.

    <br/><br/><span>DO WE COLLECT YOUR IP ADDRESS AND COOKIES ?</span>
    <br/>You can configure your browser and/or your terminal to accept or refuse cookies. We would also like to remind you that setting the parameters for the use of cookies may limit your access to certain services or features of the Site. Depending on your browsing habits, you can either save all the cookies issued on our website, or systematically refuse them, or decide on a case-by-case basis. You will find information on how to set the parameters of the main browsers by visiting their help pages or their menus, which are generally located at the top left of your screen.


    <br/><br/><span>HOW DO WE USE THIS DATA ?</span>
    <br/>We primarily use your personal data to respond to your contact, valuation and appraisal requests or to send you regular news and updates on our sales, operations and offers that may be of interest to you.
    <br/>We also use your data to enhance your digital experience on our sites and digital content: to understand your interest in their content, to manage your online account, to ensure that our sites and digital media are presented in a way that is most relevant to you and your digital equipment.


    <br/><br/><span>HOW CAN YOU ACCESS YOUR PERSONAL DATA ?</span>
    <br/>You can always contact us by post, e-mail or telephone to consult the personal data we hold about you. For this, one address: 9 Rue du Petit Fort 22100 DINAN or claude@maisonbleulin.com
    <br/>All information collected by Maison Bleu Lin is protected under Law n°78-17 of 6 January 1978 relating to data processing, files and freedoms. In accordance with the applicable provisions, you have the right to access, oppose, rectify and delete data concerning you. You may exercise this right at any time by sending an e-mail, for example, together with a photocopy of an identity document, to the following address: claude@maisonbleulin.com


    <br/><br/><span>DO WE SHARE YOUR PERSONAL DATA ?</span>
    <br/>We NEVER share your personal data with other commercial companies unless we have obtained your prior consent. We may share your personal data with certain service providers (for example, our digital agencies who manage our website, certain online operations or newsletter routings). We require these service providers to always act in accordance with applicable data protection laws and to pay particular attention to the confidentiality of such data.


    <br/><br/><span>WHERE DO WE STORE YOUR PERSONAL DATA ?</span>
    <br/>Your personal data is stored either in our databases on our own servers or in those of our service providers.


    <br/><br/><span>IS YOUR PERSONAL DATA KEPT SECURE ?</span>
    <br/>We attach great importance to data security.
    <br/>We aim to store your personal data in the safest and most secure manner at all times, and only for as long as is necessary for the purposes for which it is to be processed. To this end, we take appropriate physical and technical measures to prevent the alteration or loss of your personal data and, above all, to prevent unauthorised access to it.
  </p>
</div>

<div class="container p-3" *ngIf="lang === 'fr'">
  <h1>Mentions légales :</h1>
  <p class="legals-text p-3">
    <br/>Le site MAISON BLEU LIN est la propriété de l'entreprise MAISON BLEU LIN, entreprise individuelle de Claude LE GUEN dont le siège est situé au
    9 Rue du Petit Fort

    <br/><br/>22100 DINAN

    <br/><br/>FRANCE

    <br/><br/>RCS ST MALO 419 939 715

    <br/><br/>TVA non applicable (article 293 B du CGI)

    <br/><br/>SIRET 419 939 715 00019


    <br/><br/><br/><span>Responsable d'édition : MAISON BLEU LIN</span>

    <br/><br/>La conception graphique, le développement informatique et l'hébergement ont été réalisés / sont assurés par la société Agence 11h10, Parc d’activités BEAUJARDIN, 35410 Châteaugiron - Siret N° 84204545200021, Tél. : 02 99 41 96 76


    <br/><br/><span>Confidentialité - Données personnelles</span>
    <br/>Toutes les données personnelles recueillies sur le site sont traitées avec la plus stricte confidentialité. Nous vous rappelons que vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent (art.34 de la loi Informatique et Libertés du 6 janvier 1978). Pour exercer ce droit, adressez-vous à :

    <br/><br/>MAISON BLEU LIN
    <br/>9 Rue du Petit Fort
    <br/>22100 DINAN
    <br/>FRANCE

    <br/><br/><span>Responsabilité</span>
    <br/>MAISON BLEU LIN n'a qu'une obligation de moyens pour toutes les étapes et fonctionnalités du site. La responsabilité de MAISON BLEU LIN ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment une rupture du service, une intrusion extérieure ou la présence de virus informatiques, ou de tout fait qualifié de force majeure, conformément à la jurisprudence.

    <br/><br/><span>Propriété intellectuelle</span>
    <br/>Tous les éléments du site MAISON BLEU LIN, y compris les documents téléchargeables, sont protégés par le droit d'auteur, des marques ou des brevets. Ils sont la propriété exclusive de MAISON BLEU LIN et /ou de ces auteurs. Les créations personnelles de Claude Le Guen sont vendues sous la marque déposée BLEU LIN ET ROUGE GRIOTTE. En conséquence, vous ne pouvez en aucun cas et d'aucune manière, reproduire, représenter, diffuser, modifier, concéder tout ou partie de l'un quelconque des éléments reproduits sur le site et tout ou partie du site sans l'accord préalable et express de l'entreprise MAISON BLEU LIN et ayant droits.

    <br/><br/><span>Durée</span>
    <br/>Les présentes conditions s'appliquent pendant toute la durée de mise en ligne des services offerts sur le site MAISON BLEU LIN.

    <br/><br/><span>Loi applicable et compétence</span>
    <br/>Les présentes conditions sont soumises à la loi française. L'attribution de compétence en cas de litige, et à défaut d'accord amiable entre les parties, est donnée aux tribunaux français compétents.

    <br/><br/><span>Crédits photographiques : MAISON BLEU LIN et Ayant droits.</span>

    <br/><br/><span>Recommandations CNIL</span>
    <br/><span>I - Qu'est ce qu'un cookie</span>
    <br/>Un cookie est un fichier texte sauvegardé sur votre ordinateur ou votre appareil mobile puis récupéré lors de vos visites ultérieures.

    <br/><br/><span>II - Les obligations légales</span>
    <br/>L'internaute qui se rend sur le site éditeur se doit d'être informé de la finalités des cookies, l'éditeur doit obtenir son consentement et doit fournir aux internautes un moyen de les refuser. La durée de validité de ce consentement est de 13 mois. L' obligation du recueil du consentement s'impose aux responsables des sites internet, aux éditeurs d'applications, aux régies publicitaires, aux éditeurs de solutions de mesures d'audience ainsi qu'aux réseaux sociaux. Certains cookies nécessitent une information préalable et une demande de consentement au prêt des internautes, tels que les cookies liés aux opérations relatives à la publicité et les cookies de mesures d'audience.

    <br/><br/><span>III - L'affichage d'un bandeau d'information</span>
    <br/>Ce bandeau informe les internautes sur les finalités précises des cookies utilisés, la possibilité de s'opposer à ces cookies et de changer les paramètres en mettant un lien présent sur le bandeau. Le lien «en savoir plus» permet à l'utilisateur de paramétrer les cookies, les accepter ou les refuser. Ce lien doit informer de manière claire et concise l'usager ce qu'est un cookie.

    <br/><br/><span>IV - Les solutions Webanalytics</span>
    <br/>Piwik et Google Analytics sont des outils de mesures d'audience qui permettent d'obtenir des informations sur la navigation des visiteurs. Ce sont des outils dispensés de consentement mais devant respecter certaines normes. Le mécanisme d'opposition aux cookies doit être accessible simplement, les cookies déposés servent uniquement à la production de statistiques anonymes, les cookies ne doivent pas permettre de suivre la navigation de l'internaute sur d'autres sites, les cookies permettant la traçabilité des internautes et les adresse IP ne doivent pas être conservés au-delà de 13 mois à compter de la dernier visite.


    <br/><br/><span>V - Les boutons sociaux</span>
    <br/>Share Privacy est un outil qui permet d'intégrer les boutons des principales plates-formes sociales sans envoyer de cookies avant d'obtenir un consentement préalable de l'utilisateur. Les boutons sociaux sont des plug-ins sociaux qui permettent aux concepteurs de site d'ajouter facilement à leurs pages web des fonctionnalités permettant de faciliter le partage du contenu de leurs sites sur les différentes plates-formes sociales. Ce sont des outils qui nécessitent un consentement préalable de l'internaute.

    <br/><br/><br/>Traitement des données - (En conformité avec la loi RGPD)

    <br/><br/>Normes RGPD - LOI n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles

    <br/><br/>MAISON BLEU LIN  attache une grande importance à la protection de vos données personnelles.
    <br/>Afin de suivre cette éthique, nous vous proposons de prendre connaissance de notre charte qui complète les conditions d'utilisation actuelle du Site ainsi que nos mentions légales.
    <br/>Si vous êtes en désaccord avec ces termes, vous êtes libre de ne pas naviguer sur MAISON BLEU LIN et de ne nous fournir aucune donnée personnelle.

    <br/><br/><span>À QUOI LA PRESENTE POLITIQUE DE CONFIDENTIALITE ET DE PROTECTION DES DONNEES PERSONNELLES S'APPLIQUE-T-ELLE ?</span>

    <br/><br/>La présente politique de protection des données personnelles s'applique à l'ensemble du site internet, des applications et supports digitaux, ainsi qu'à toute autre initiative en ligne type jeux-concours ou tirages au sort appartenant à l'éditeur du site et procédant à la collecte de données personnelles. La présente politique ne s'applique aucunement aux sites internet de tiers utilisant notre nom commercial à des fins de promotion, y compris à ceux éventuellement mentionnés sur notre support par l'intermédiaire d'un lien internet comme peuvent l'être des sites "Partenaires".

    <br/><br/><span>QUELLES SONT LES DONNEES COLLECTEES ?</span>

    <br/>Avec votre consentement préalable, lorsque vous utilisez notre Supports nous sommes susceptibles de collecter et de traiter tout ou partie des données suivantes :
    <br/>> Prénom et nom de famille ;
    <br/>> Adresse de courrier électronique ;
    <br/>> Téléphone ;
    <br/>> Département ;
    <br/>> Toute demande particulière que vous pourriez nous adresser (principalement pour des besoins d'archivage) de type expertise et estimation.

    <br/><br/><span>COLLECTONS-NOUS VOTRE ADRESSE IP ET VOS TEMOINS DE CONNEXION (COOKIES)?</span>

    <br/>Vous pouvez configurer votre navigateur et/ou votre terminal afin d'accepter ou refuser les cookies. Nous tenons par ailleurs à vous rappeler que le paramétrage de l'utilisation des cookies peut limiter votre accès à certains services ou fonctionnalités du Site. Vous pouvez, selon vos habitudes de navigation, soit enregistrer tous les cookies émis sur notre site Internet, soit les refuser systématiquement, ou encore décider au cas par cas. Vous trouverez des informations sur le paramétrage des principaux navigateurs en vous rendant sur leurs pages d'aide ou sur leurs menus situés généralement en haut à gauche de votre écran.

    <br/><br/><span>COMMENT UTILISONS-NOUS CES DONNEES ?</span>

    <br/><br/>Nous utilisons principalement vos données personnelles dans le cadre de nos réponses à vos demandes de contact, expertise et d'estimation ou pour vous envoyer régulièrement nos actualités et des nouvelles sur nos ventes, nos opérations et nos offres susceptibles de susciter votre intérêt.
    <br/>Nous utilisons également vos données pour améliorer votre expérience digitale sur nos sites et contenus digitaux : comprendre l'intérêt que vous portez à leur contenu, gérer votre compte en ligne, faire en sorte que nos sites et supports digitaux soient présentés de la manière qui soit la plus adaptée à vous et à votre matériel numérique.

    <br/><br/><span>COMMENT POUVEZ-VOUS ACCEDER à VOS DONNEES PERSONNELLES ?</span>

    <br/><br/>Vous avez toujours la possibilité de nous contacter par courrier postal, par courrier électronique ou par téléphone pour consulter les données personnelles vous concernant qui sont en notre possession. Pour cela, une adresse : 9 Rue du Petit Fort 22100 DINAN ou claude@maisonbleulin.com
    <br/>Tous les renseignements recueillis par Maison Bleu Lin sont protégés en vertu de Loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Conformément aux dispositions applicables, vous bénéficiez d'un droit d'accès, d'opposition, de rectification et de suppression des données qui vous concernent. Vous pouvez exercer ce droit à tout moment en adressant par exemple un courrier électronique, accompagné d'une photocopie d'une pièce d'identité, à l'adresse suivante : claude@maisonbleulin.com

    <br/><br/><span>PARTAGEONS-NOUS VOS DONNEES PERSONNELLES ?</span>

    <br/><br/>Nous ne communiquons JAMAIS vos données personnelles à d'autres entreprises commerciales à moins que nous n'ayons préalablement obtenu votre consentement. Nous sommes susceptibles de communiquer vos données personnelles à certains prestataires de services (par exemple, nos agences digitales qui gèrent notre site Internet, certaines opérations en ligne ou les routages de newsletter). Nous demandons à ces prestataires de toujours agir en conformité avec les lois applicables en matière de protection de données personnelles et d'accorder une attention particulière à la confidentialité de ces données.

    <br/><br/><span>OU STOCKONS-NOUS VOS DONNEES PERSONNELLES ?</span>

    <br/><br/>Vos données personnelles sont stockées soit dans nos bases de données sur serveurs en interne, soit dans ceux de nos prestataires de services.

    <br/><br/><span>VOS DONNEES PERSONNELLES SONT-ELLES CONSERVEES EN SECURITE ?</span>

    <br/><br/>Nous attachons une grande importance à la sécurisation des données.
    <br/>Nous avons pour objectif de toujours conserver vos données personnelles de la manière la plus sûre et la plus sécurisée, et uniquement pendant la durée nécessaire à la réalisation de la finalité poursuivie par le traitement. Dans cette perspective, nous prenons les mesures physiques et techniques appropriées pour empêcher leur altération ou leur perte mais surtout tout accès non autorisé à celles-ci.
  </p>
</div>
