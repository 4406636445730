<app-intro [image]="'/assets/images/bandeau-titre/bandeau-pressbook.jpg'" [titre]="'Pressbook'"
           [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>

<div class="container my-5">
  <div class="row">
    <div class="col-lg-2 text-center text-lg-start">
      <img class="img-fluid" src="/assets/images/intro/flower.png"/>
    </div>
    <div class="col-lg-10 text-center text-lg-start">
      <p>{{'PRESS-BOOK.PITCH'|translate}}</p>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="row">
    <div class="row text-start my-5">
      <a href="#" target="_blank" class="col-lg-4 pressbook-link" *ngFor="let pressbook of pressbooks">
        <h4 class="text-dark-green">{{pressbook.title}}</h4>
        <p>{{pressbook.legend}} / {{pressbook.releasedAt | date}}</p>
        <img class="img-pressbook" src="{{S3 + pressbook.thumbnail}}" alt="">
        <div class="text-center">
          <a href="{{S3 + pressbook.article}}" type="button" class="btn btn-primary mt-3" target="_blank">{{'PRESS-BOOK.ARTICLE.SEE-ARTICLE'|translate}}</a>
        </div>
      </a>
    </div>
  </div>
</div>
<app-newsletter></app-newsletter>
