import {Component, Input, OnInit} from '@angular/core';
import {ProduitService} from "../../_services/produit.service";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CartService} from "../../_services/cart.service";
import {TokenStorageService} from "../../_services/token-storage.service";
import {Notification, Observable, of} from "rxjs";
import {NotificationService} from "../../_services/notification.service";

const API = `${environment.api}/api/products`;
const S3 = `${environment.s3}`;

@Component({
  selector: 'app-product-describe',
  templateUrl: './product-describe.component.html',
  styleUrls: ['./product-describe.component.scss']
})
export class ProductDescribeComponent implements OnInit
{

  produit: any;
  slug: any;
  S3 = S3;
  products: any = [];
  p: number = 1;
  stock: number = 0;

  //determine si un produit est nouveau
  isNew = false;
  //determine le nombre de jour pour qu'un produit soit considéré comme nouveau
  nbJour = 15;

  productConcat: string = "";
  labelProduit: string = "";

  shareUrl: string | null = null;


  constructor(
    private http: HttpClient,
    private produitService: ProduitService,
    private route: ActivatedRoute, private router: Router,
    public translate: TranslateService,
    private cartService: CartService,
    private tokenStorage: TokenStorageService,
    private notif: NotificationService
  )
  {
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void
  {
    this.translate.onLangChange.subscribe((event) =>
    {
      this.produit = null;
      this.getProduct(this.slug);
      this.getSimilarProducts();
    })
    this.getProduct(this.slug);
    this.shareUrl = window.location.href;
  }

  getProduct(produitId: number)
  {
    this.produitService.getOneProductById(produitId, this.translate.currentLang).subscribe(
      (data) =>
      {
        this.produit = data;
        this.labelProduit = this.produit.label;
        this.productConcat = this.produit.category.parent.label + " | " + this.produit.category.label;
        if (new Date(this.produit.createdAt) > new Date(new Date().getTime() - this.nbJour * 24 * 60 * 60 * 1000)) {
          this.isNew = true;
        }
        this.getSimilarProducts()
      })
  }

  getSimilarProducts()
  {
    this.produitService.getRelatedProducts(1, 6, this.translate.currentLang, this.produit.category.slug).subscribe(
      (data) =>
      {
        this.products = data;
      }
    )
  }

  changeMainImage(img: HTMLImageElement)
  {
    const mainImage = document.getElementById('main-image') as HTMLImageElement;
    mainImage.src = this.S3 + img;
  }

  addToCart(qty: any)
  {
    let produit = {productId: this.produit.id};
    if (qty.value <= this.produit.quantityInStock) {
      this.cartService.addProductToCart(produit, this.tokenStorage.getToken(), parseInt(qty.value)).subscribe();
      this.notif.notifyTopRight(this.produit.label);
    } else {
      this.notif.notifyTopRightFail('Quantité non disponible');
    }
  }
}
