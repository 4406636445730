import {Component, Input} from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {environment} from "../../environments/environment";

@Component({
  template: `
        <div class="modal-content">
          <div class="modal-header">
            <h5 *ngIf="produit.error" class="modal-title">{{'CART.ORDER.ERROR.ADDED'|translate}}</h5>
            <h5 *ngIf="!produit.error" class="modal-title">{{'CART.ORDER.ADDED'|translate}}</h5>
              <span  style="cursor: pointer" class=" display-6" (click)="activeModal.dismiss('Cross click')" aria-hidden="true">&times;</span>
          </div>
          <div class="modal-body">
            <div class="row d-flex align-items-center">
              <div class="col-lg-4">
                    <img class="img-fluid" src="{{ S3 +  produit.image }}" >
              </div>
              <div class="col-lg-8">
                <p class="text-dark-green">{{ produit.titre }}</p>
                <div class="border w-25 border-dark-green mb-3"></div>
                <p *ngIf="produit.error" class="text-dark-green">{{produit.error|translate}}</p>
                <div *ngIf="!produit.error">
                  <p class="text-dark-green">{{ produit.prix }} €</p>
                  <p>{{ produit.description }}</p>
                  <a class="btn btn-primary d-block shadow-none" (click)="activeModal.dismiss('Cross click')" [routerLink]="'/panier'">{{'CART.ORDER.CONFIRM' | translate}}</a>
                  <a class="btn btn-secondary d-block mt-3 shadow-none" (click)="activeModal.dismiss('Cross click')">{{'CART.ORDER.CONTINUE' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style>
          .btn-primary{
            width: 100% !important;
          }

          .modal-title{
            font-size: 1rem;
            color: #3F595D !important;
          }
        </style>
  `,
})
export class ModalProductComponent {
  @Input() produit = {titre : "titre du produit", image : "ai", prix: 8, description: "dsdd", error: null};
  S3 = `${environment.s3}`;
  constructor(public activeModal: NgbActiveModal) {
  }
}
