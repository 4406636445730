import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../../_services/auth.service";

@Component({
  selector: 'app-profil-informations',
  templateUrl: './profil-informations.component.html',
  styleUrls: ['./profil-informations.component.scss']
})
export class ProfilInformationsComponent implements OnInit {

  userDetails: any = [];

  form: any = {
    nom: null,
    prenom: null,
    adresse: null,
    zipCode: null,
    country: null,
    locality: null,
    tel: null,
  };

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.getUserDetails()
  }

  onSubmit(): void {
    const {email, nom, prenom, adresse, zipCode, country, locality, tel} = this.form;

    this.authService.updateUserDetails(this.form, this.userDetails.id).subscribe();
  }

  getUserDetails() {
    this.authService.getUserDetails().subscribe(
      data => {
        this.userDetails = data;
      })
  }

}
