import { Component, OnInit } from '@angular/core';
import {CartService} from "../../_services/cart.service";
import {TokenStorageService} from "../../_services/token-storage.service";
import {Product} from "../../_models/product";
import {CartItem} from "../../_models/cart-item";
import {MessengerService} from "../../_services/messenger.service";
import {NotificationService} from "../../_services/notification.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  cartItems = [];
  isLoggedIn = false;
  cartTotal = 0;
  cartNb = 0;

  constructor(private cartService: CartService, private tokenStorage: TokenStorageService, private msg: MessengerService, private notification: NotificationService, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.onLangChange.subscribe((event) => {
      this.cartItems = [];
      this.handleSubscription();
      this.loadCartItems();
      this.calcCartTotal();

      if (this.tokenStorage.getToken()) {
        this.isLoggedIn = true;
      }
    })
    this.handleSubscription();
    this.loadCartItems();
    this.calcCartTotal();

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
  }

  handleSubscription() {
    // @ts-ignore
    this.msg.getMsg().subscribe((product: Product) => {
      this.loadCartItems();
    })
  }

  loadCartItems() {
    this.cartService.getCartItems(this.tokenStorage.getToken()).subscribe((items: CartItem[]) => {
      items.forEach(item => {
        if(item.qty > item.quantityInStock) {
          item.qty = item.quantityInStock;
          // @ts-ignore
          item.stockNotAvailable = true;
        }
        else{
          // @ts-ignore
          item.stockNotAvailable = false;
        }
      })

      // @ts-ignore
      this.cartItems = items;
      this.cartNb = this.cartItems.length
      this.calcCartTotal();
    })
  }


  calcCartTotal() {
    this.cartTotal = 0
    this.cartItems.forEach(item => {
      // @ts-ignore
      this.cartTotal += (item.qty * item.price)
    })
  }
  validateOrder() {
    this.cartService.validateOrder().subscribe((response: any) => {
      if(this.translate.currentLang === 'fr') {
        if(response.status === 409) {

          this.notification.notifyTopRightFail('Un élément de votre panier n\'est plus disponible');
        }
        else {
          this.notification.notifyTopRight('Votre commande a bien été validée ! , vous pouvez désormais consulter votre bon de commande dans votre profil');
          this.loadCartItems();
        }
      }

      if(this.translate.currentLang === 'en') {
        if(response.status === 409) {

          this.notification.notifyTopRightFail('An item in your cart is no longer available');
        }
        else {
          this.notification.notifyTopRight('Your order has been validated ! , you can now consult your order in your profile');
          this.loadCartItems();
        }
      }
    })
  }
}
