import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "../../_services/notification.service";
import {NgForm} from "@angular/forms";
import {environment} from "../../../environments/environment";

const API = `${environment.api}/api/newsletter_subscribers`;

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  form: any = {
    name: null,
    email: null,
  };

  @Output() submitSuccess: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onFormSubmit(form:NgForm){
    const {name, email} = this.form;

    if(form.valid){
      const body = {
        "name": form.form.value['name'],
        "email": form.form.value['email'],
      }
      this.http.post<any>(API, body).subscribe(response => {
        this.notificationService.notifyTopRight('Votre inscription à note newsletter bien été prise en compte');
      }, error => {
        this.notificationService.notifyTopRight('Une erreur est survenue, veuillez réessayer');
      }, ()=>{this.submitSuccess.emit(true)});
    }
  }
}
