<app-intro [image]="'/assets/images/bandeau-titre/header-cat-actu.jpg'" [titre]=" 'FOOTER.NAVIGATION.NEWS'|translate " [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>

<div class="container my-5">
  <div class="row">
    <div class="col-lg-2 text-center text-lg-start">
      <img class="img-fluid" src="/assets/images/intro/flower.png"/>
    </div>
    <div class="col-lg-10 text-center text-lg-start">
      <p>{{'NEWS.PITCH'|translate}}</p>
    </div>
  </div>
</div>
<div class="container mb-3 actualites">
  <div class="row align-items-start">
    <div class="col-lg-4 my-4" *ngFor="let item of news">
      <img src="{{S3 + item.thumbnail}}" alt="">
      <p>{{item.dateDebut | date: 'd MMMM y' }}</p>
      <p><strong>{{item.title | slice:0: 50}}</strong></p>
      <div class="text-center">
        <a [routerLink]="'/actualites/' + item.id" type="button" class="btn btn-primary">{{'NEWS.SEE-MORE'|translate}}</a>
      </div>
    </div>
  </div>
  <div class="text-center mt-5">
    <button type="button" class="btn btn-primary">{{'NEWS.SEE-ALL'|translate}}</button>
  </div>
</div>
<app-newsletter></app-newsletter>
