import { Component, OnInit } from '@angular/core';
import {ActualitesService} from "../../_services/actualites.service";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  news : any = [];
  S3 = `${environment.s3}`;
  constructor(private actualitesServices: ActualitesService,private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.news = [];
      this.getNews();
    })
    this.getNews();
  }

  getNews() {
    this.actualitesServices.getActualites(this.translate.currentLang).subscribe(
      (data) => {
        this.news = data;
      })
  }

}
