import {Component, Input, OnInit} from '@angular/core';
import {CartService} from "../../../_services/cart.service";
import {TokenStorageService} from "../../../_services/token-storage.service";
import {CartComponent} from "../cart.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  S3 = `${environment.s3}`;
  @Input() cartItem: any;

  constructor(private cartService: CartService, private tokenStorage: TokenStorageService, private panier: CartComponent) {
  }

  ngOnInit(): void {
  }

  removeItemFromcart(productId: number, qty: number, fullDelete: boolean = false) {
    if (fullDelete) {
      // @ts-ignore
      this.cartService.removeFromCart(this.cartItem, this.tokenStorage.getToken()).subscribe();
      // @ts-ignore
      document.getElementById('cart-' + this.cartItem.id).style.display = 'none';
      this.panier.calcCartTotal();
    }
    else{
      if (this.cartItem.qty == 1 || isNaN(qty)) {
        return;
      }

      else{
        this.cartItem.qty -= qty;
        this.cartService.addProductToCart(this.cartItem, this.tokenStorage.getToken(), parseInt(this.cartItem.qty)).subscribe()
        this.panier.calcCartTotal();
      }
    }
  }

  addItemToCart(qty: any) {
    if (this.cartItem.qty == 99 || isNaN(qty) || this.cartItem.quantityInStock < this.cartItem.qty + 1) {
      return;
    }
    else{
      this.cartItem.id = this.cartItem.productId;
      this.cartItem.qty += qty;
      this.cartService.addProductToCart(this.cartItem, this.tokenStorage.getToken(), parseInt(this.cartItem.qty)).subscribe()
      this.panier.calcCartTotal();
    }
  }


  manageNbQty(qty: any) {
    if (isNaN(qty) || qty <= 1 || qty >= 99 || this.cartItem.quantityInStock < qty) {
      return;
    }
    else {
      this.cartItem.qty = qty;
      this.cartService.addProductToCart(this.cartItem, this.tokenStorage.getToken(), parseInt(qty)).subscribe()
      this.panier.calcCartTotal();
    }
  }

  slugify(str: any) {
    return str.toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }
}
