<div class="text-center mb-5" id="newsletter">
  <p>{{'NEWSLETTER.PITCH-2' | translate}}</p>
  <a target="_blank" href="https://www.instagram.com/maisonbleulin/" class="btn btn-primary">{{'NEWSLETTER.INSTAGRAM' | translate}}<i class="fa fa-instagram"></i></a>
</div>
<div class="container-newsletter">
  <div class="row align-items-center text-center text-lg-start">
    <div class="col-lg-2">
      <img class="img-fluid" src="/assets/images/flower-2.png" alt="">
    </div>
    <div class="col-lg-10">
      <div class="row align-items-start ">
        <div class="mb-3">
          <h4 class="mb-3"><strong>{{"NEWSLETTER.TITLE"|translate}}</strong></h4>
          <p>{{"NEWSLETTER.PITCH"|translate}}</p>
        </div>
        <div class="container">
          <div class="row justify-content-start">
            <form #contactForm="ngForm" (ngSubmit)="onFormSubmit(contactForm)" #f="ngForm" novalidate>
              <div class="row align-items-start mb-4">
                <div class="col-lg-3">
                  <input type="text" class="form-control" ngModel name="name" id="name"
                         placeholder="{{'NEWSLETTER.FORM.PLACEHOLDERS.LASTNAME'|translate}}" required>
                </div>
                <div class="col-lg-3 my-3 my-lg-0">
                  <input type="text" class="form-control" ngModel name="prenom" id="prenom"
                         placeholder="{{'NEWSLETTER.FORM.PLACEHOLDERS.FIRSTNAME'|translate}}" required>
                </div>
                <div class="col-lg-3">
                  <input type="text" class="form-control" ngModel name="email" id="email"
                         placeholder="{{'NEWSLETTER.FORM.PLACEHOLDERS.EMAIL'|translate}}" required>
                </div>
                <div class="col-lg-3 mt-3 mt-lg-0 text-center text-lg-start">
                  <button class="btn btn-primary" type="submit">{{'NEWSLETTER.FORM.BUTTONS.CONFIRM'|translate}}</button>
                </div>
              </div>
              <div class="form-check mb-5">
                <input class="form-check-input" type="checkbox" name="legals" id="legals" #legals="ngModel" [(ngModel)]="form.legals" required>
                <label class="form-check-label" for="legals">
                  {{'NEWSLETTER.FORM.CHECKBOX.CONSENT.TEXT'|translate}}
                  <a href="/mentions-legales">{{'NEWSLETTER.FORM.CHECKBOX.CONSENT.SEE-MORE'|translate}}</a></label>
                <mat-error *ngIf="contactForm.submitted && legals.errors?.required">Vous devez accepter les mentions légales.</mat-error>
              </div>
            </form>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
