<app-intro [image]="'/assets/images/bandeau-titre/header-cat-'+ slugCategory +'.jpg'" [titre]="'FOOTER.NAVIGATION.CATALOG'|translate"
           [sousTitre]="'FOOTER.NAVIGATION.CATALOG'|translate" [focus]="mainCateg?.label"></app-intro>
<section class="section-catalogue">
  <div class="container-catalogue">
    <div class="row d-flex align-items-end">
      <div class="col-lg-2 text-center text-lg-start">
        <img class="img-fluid" src="/assets/images/flower-2.png">
      </div>
      <div class="col-lg-10 text-center text-lg-start">
        <p class="mb-4 fw-bold">{{'HOME-ADVICE' | translate}}</p>
        <p class="mb-4">{{"CATALOG.PITCH"|translate}}</p>
        <p class="mb-4">
          {{'FOOTER.INFO-MERCERIE'|translate}}
        </p>
        <div class="subcategory-container">
          <div>
            <img class="img-fluid" src="/assets/images/cat-{{slugCategory}}.jpg" alt=""/>
          </div>
          <div class="subcategory-wrapper">
            <h2 class="text-dark-green brocante" *ngIf="!slugSubCateg">{{mainCateg?.label}}</h2>
            <h2 class="text-dark-green brocante" *ngIf="slugSubCateg">
              {{mainCateg?.label}} &bullet; {{subCateg?.label}}
            </h2>
            <a *ngIf="slugSubCateg" class="retour" [routerLink]="'/catalogue/' + mainCateg?.slug">{{'CATALOG.RETURN' | translate }} {{mainCateg?.label}}</a>

             <div class="subcategory">
               <a *ngFor="let c of category" [routerLink]="'/catalogue/' + slugCategory + '/' + c.slug">{{c?.label}}</a>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container-produits">
  <div class="align-items-start">
    <div class="mb-3 row" *ngIf="products.length > 0">
      <div class="col-lg-3" *ngFor="let p of products | paginate: { itemsPerPage: 12, currentPage: p }">
        <app-product [product]="p"></app-product>
      </div>
      <pagination-controls (pageChange)="p = $event" previousLabel="" nextLabel="" class="float-right"></pagination-controls>
    </div>
    <div class="mb-3 row" *ngIf="products.length <= 0">
      <div class="col-lg-12">
        <p class="text-center">{{"CATALOG.NO-PRODUCTS"|translate}} <span class="fw-bold">{{subCateg?.label}}</span></p>
      </div>
    </div>
  </div>
</div>
<app-newsletter></app-newsletter>
