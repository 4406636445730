import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cgv',
  templateUrl: './cgv.component.html',
  styleUrls: ['./cgv.component.scss']
})
export class CgvComponent implements OnInit {

  lang = this.translate.currentLang;
  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((event) => {
      this.lang = event.lang;
    })
  }

  ngOnInit(): void {
  }

}
