import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {TokenStorageService} from "../../../_services/token-storage.service";
import {CartService} from "../../../_services/cart.service";

export interface CommandeElement {
  id: number;
  name: string;
  //total: number;
  facture: string;
}

@Component({
  selector: 'app-profil-commandes',
  templateUrl: './profil-commandes.component.html',
  styleUrls: ['./profil-commandes.component.scss']
})
export class ProfilCommandesComponent implements OnInit {

  displayedColumn: string[] = ['id', 'name', 'facture'];
  dataSource!: MatTableDataSource<CommandeElement>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  commandes : any;

  token = this.tokenStorage.getToken();

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService, private cartService: CartService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {

    this.dataSource = new MatTableDataSource(this.commandes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.cartService.getCommandes(this.token).subscribe(data => {
      // @ts-ignore
      this.commandes = data;
      this.dataSource = new MatTableDataSource(this.commandes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  getFacture(id: any) {
    this.cartService.getFacture(id);
  }
}
