import { Component, OnInit } from '@angular/core';
import {PartenairesService} from "../../_services/partenaires.service";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

const S3 = `${environment.s3}`;
@Component({
  selector: 'app-partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.scss']
})
export class PartenairesComponent implements OnInit {

  S3 = S3;
  partenaires: any = [];
  constructor(private partenairesServices: PartenairesService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.partenaires = [];
      this.getPartenaires();
    })
    this.getPartenaires()
  }

  getPartenaires(){
    this.partenairesServices.getPartenaires(this.translate.currentLang).subscribe(data => {
      this.partenaires = data;
    })
  }
}
