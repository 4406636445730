<app-intro [image]="'/assets/images/bandeau-titre/bandeau-contactez-nous.jpg'" [titre]="'Contact'"
           [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>
<section class="contact-form-section">
  <div class="container">
    <div class="row">
      <div class="row col-xxl-4 col-xl-12 text-xxl-start text-center left-col">
        <div>
          <h2>{{"CONTACT.ADDRESS"|translate}}</h2>
          <p>
            Maison Bleu lin
            <br>9 Rue du Petit Fort,
            <br>22100 Dinan
          </p>
          <div class="border-left"></div>
          <br>
          <h2>{{"CONTACT.PHONE-NUMBER"|translate}}</h2>
          <p>
            +336 99 25 95 72
          </p>
          <div class="border-left"></div>
          <br>
          <h2>{{"CONTACT.EMAIL"|translate}}</h2>
          <p>
            claude@maisonbleulin.com
          </p>
        </div>

      </div>

      <div class="col-xxl-8 mx-auto">
        <form name="form" #contactForm="ngForm" (ngSubmit)="onFormSubmit(contactForm)" #f="ngForm" novalidate>
          <div class="form-floating">
            <h3>{{"CONTACT.INQUIRY.HEADER"|translate}}</h3>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" ngModel name="name" id="name"
                         placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.LASTNAME.PLACEHOLDER'|translate}}" required>
                  <label for="name">{{'CONTACT.INQUIRY.FORM.FIELDS.LASTNAME.LABEL'|translate}}</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" ngModel name="prenom" id="prenom"
                         placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.FIRSTNAME.PLACEHOLDER'|translate}}" required>
                  <label for="prenom">{{'CONTACT.INQUIRY.FORM.FIELDS.FIRSTNAME.PLACEHOLDER'|translate}}</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" ngModel name="email" id="email"
                         placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.EMAIL.PLACEHOLDER'|translate}}" required>
                  <label for="email">{{'CONTACT.INQUIRY.FORM.FIELDS.EMAIL.LABEL'|translate}}</label>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" ngModel name="phoneNumber" id="phoneNumber"
                         placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.PHONE-NUMBER.PLACEHOLDER'|translate}}" required>
                  <label for="phoneNumber">{{'CONTACT.INQUIRY.FORM.FIELDS.PHONE-NUMBER.LABEL'|translate}}</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" ngModel name="cp" id="cp"
                         placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.ZIP-CODE.PLACEHOLDER'|translate}}" required>
                  <label for="cp">{{'CONTACT.INQUIRY.FORM.FIELDS.ZIP-CODE.LABEL'|translate}}</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" ngModel name="ville" id="ville"
                         placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.CITY.PLACEHOLDER'|translate}}" required>
                  <label for="ville">{{'CONTACT.INQUIRY.FORM.FIELDS.CITY.LABEL'|translate}}</label>
                </div>
              </div>
            </div>

            <div class="form-floating mb-2">
              <textarea class="form-control"
                        placeholder="{{'CONTACT.INQUIRY.FORM.FIELDS.MESSAGE.PLACEHOLDER'|translate}}" ngModel
                        name="message" id="message"
                        style="height: 100px" required></textarea>
              <label for="message">{{'CONTACT.INQUIRY.FORM.FIELDS.MESSAGE.LABEL'|translate}}</label>
            </div>

            <div class="d-flex">
              <input class="input-rgpd" type="checkbox" name="legals" id="legals" #legals="ngModel" [(ngModel)]="form.legals" required>
              <label class="control-label label-rgpd">{{'CONTACT.INQUIRY.FORM.CHECKBOX.CONSENT.TEXT'|translate}} <a
                href="/mentions-legales">{{'CONTACT.INQUIRY.FORM.CHECKBOX.CONSENT.SEE-MORE'|translate}}</a>
              </label>
            </div>
            <mat-error *ngIf="contactForm.submitted && legals.errors?.required">Vous devez accepter les mentions légales.</mat-error>


            <div class="text-xxl-start text-center">
              <button class="btn btn-primary mt-3 mb-3" type="submit">{{'CONTACT.INQUIRY.FORM.BUTTONS.SEND'|translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-map></app-map>
</section>
