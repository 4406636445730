<ngx-slick-carousel class="carousel"
                    #slickModal="slick-carousel" [config]="slideConfig">
  <div ngxSlickItem *ngFor="let s of slider.panels" class="slide">
    <app-slider [carousel]="slickModal" [slider]="s"></app-slider>
  </div>
</ngx-slick-carousel>
<div class="en-savoir-plus text-center">
  <p>
    {{'HOME-ADVICE' | translate}}
  </p>
</div>
<div class="container-produits">
  <h2 class="my-5"><u>{{'HOME.OUR-PRODUCTS' | translate}}</u></h2>
  <div class="carousel-row">
    <a class="prev-carousel-produits" (click)="slickModalProductPrev()">
      <i class="fa-solid fa-chevron-left"></i>
    </a>
    <div class="carousel-container">
      <ngx-slick-carousel class="carousel" #slickModalProduct="slick-carousel" [config]="slideConfigProduct">
        <div ngxSlickItem *ngFor="let p of treasures" class="slide px-3">
          <app-product [product]="p"></app-product>
        </div>
      </ngx-slick-carousel>
    </div>
    <a class="next-carousel-produits" (click)="slickModalProductNext()">
      <i class="fa-solid fa-chevron-right"></i>
    </a>
  </div>
</div>
<div class="en-savoir-plus text-center">
  <h1>{{ 'HOME.WELCOME' | translate }}</h1>
  <p>{{ 'HOME.PITCH-2' | translate }}</p>
  <a [routerLink]="'/about'" class="btn btn-primary">{{'REGISTER.SEE-MORE' | translate}}</a>
</div>
<div class="container my-3 text-center">
  <img class="img-fluid mb-3" src="/assets/images/intro/flower.png"/>
  <p>{{'HOME.PITCH'|translate}}</p>
</div>
<div class="container-produits">
  <h2 class=" my-5"><u>{{'HOME.NEWS' | translate}}</u></h2>
  <div class="carousel-row">
    <a class="prev-carousel-produits" (click)="slickModalProductNewsPrev()">
      <i class="fa-solid fa-chevron-left"></i>
    </a>
    <div class="carousel-container">
      <ngx-slick-carousel class="carousel"
                          #slickModalProductNews="slick-carousel" [config]="slideConfigProduct">
        <div ngxSlickItem *ngFor="let p of products" class="slide px-3">
          <app-product [product]="p"></app-product>
        </div>
      </ngx-slick-carousel>
    </div>
    <a class="next-carousel-produits" (click)="slickModalProductNewsNext()">
      <i class="fa-solid fa-chevron-right"></i>
    </a>
  </div>
</div>
<div class="w-100 text-center">
  <a [routerLink]="'/nouveautes'" class="btn btn-primary">{{'HOME.SEE-ALL-NEWS' | translate}}</a>
</div>
<app-newsletter></app-newsletter>
