<app-intro [image]="'/assets/images/bandeau-titre/header-cat-partenaires.jpg'" [titre]="'FOOTER.NAVIGATION.PARTNERS'|translate"
           [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>
<section class="partenaire-section">
  <div class="partenaire-container">
    <a href="{{p.url}}" target="_blank" class="partenaire" *ngFor="let p of partenaires">
      <div class="logo">
        <img src="{{S3 + p.logo}}">
      </div>
      <div class="text">
        <h1>{{p.name}}</h1>
        <p>{{p.description}}</p>
      </div>
    </a>
  </div>
</section>
