import {Component, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ProduitService} from "../../_services/produit.service";
import {SliderService} from "../../_services/slider.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  products : any = [];
  treasures: any = [];
  slider : any = [];

  constructor(private http: HttpClient, private produitService: ProduitService, private sliderService: SliderService, public translate: TranslateService) { }
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;
  @ViewChild('slickModalProductNews') slickModalProductNews!: SlickCarouselComponent;
  @ViewChild('slickModalProduct') slickModalProduct!: SlickCarouselComponent;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false
  };

  slideConfigProduct = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 968,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 460,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  getProducts(){
    this.produitService.getProducts(1, 20, this.translate.currentLang).subscribe((data) => {
      // @ts-ignore
      data.forEach((element: any) => {
        if (element.quantityInStock != null && element.quantityInStock > 0){
          this.products.push(element);
        }
      })
    })
  }
  getRandomProducts(){
    this.produitService.getRandomProducts(12, this.translate.currentLang).subscribe((data) => {
      // @ts-ignore
      this.treasures = data;
    })
  }

  getSliders(){
    this.sliderService.getSliders(this.translate.currentLang).subscribe((data) => {
      // @ts-ignore
      this.slider = data[0];
    })
  }

  prev() {
    this.slickModal.slickPrev();
  }

  next() {
    this.slickModal.slickNext();
  }

  slickModalProductPrev() {
    this.slickModalProduct.slickPrev();
  }
  slickModalProductNext() {
    this.slickModalProduct.slickNext();
  }

  slickModalProductNewsNext() {
    this.slickModalProductNews.slickNext();
  }

  slickModalProductNewsPrev() {
    this.slickModalProductNews.slickPrev();
  }

  getInstagramPosts(){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    this.http.get('https://www.instagram.com/fibawc/?__a=1', {headers: headers}).subscribe((data) => {
    })
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.products = [];
      this.getProducts();
      this.getRandomProducts();
      this.slider = [];
      this.getSliders();
    })
    this.getProducts();
    this.getSliders();
    this.getRandomProducts();
  }

}
