<app-intro [image]="'/assets/images/bandeau-titre/header-cat-nouveaute-2.jpg'" [titre]="'FOOTER.NAVIGATION.NEW-PRODUCTS'|translate" [sousTitre]="'FOOTER.NAVIGATION.HOME'|translate"></app-intro>

<div class="container my-5">
  <div class="row">
    <div class="col-lg-2 text-center text-lg-start">
      <img class="img-fluid" src="/assets/images/intro/flower.png"/>
    </div>
    <div class="col-lg-10 text-center text-lg-start">
      <p>{{'NEW-PRODUCTS.PITCH'|translate}}</p>
    </div>
  </div>
</div>
<div class="section-nouveautes">
  <div class="align-items-start">
    <h3 class="mb-5 text-center"><u>{{'NEW-PRODUCTS.PITCH'|translate}}</u></h3>
    <div class="mb-3 row">
      <div class="col-lg-3" *ngFor="let p of products | paginate: { itemsPerPage: 12, currentPage: p }">
        <app-product [product]="p"></app-product>
      </div>
    </div>
    <pagination-controls (pageChange)="p = $event" previousLabel="" nextLabel="" class="float-right"></pagination-controls>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
<app-newsletter></app-newsletter>
