import { Component, OnInit } from '@angular/core';
import {ProduitService} from "../../_services/produit.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-news-catalog',
  templateUrl: './news-catalog.component.html',
  styleUrls: ['./news-catalog.component.scss']
})
export class NewsCatalogComponent implements OnInit {

  products : any = [];
  p: number = 1;
  constructor(private productService: ProduitService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event) => {
      this.products = [];
      this.getProducts();
    })
    this.getProducts();
  }
  getProducts() {
    this.productService.getProducts(1, 999, this.translate.currentLang).subscribe((data) => {
      // @ts-ignore
      data.forEach((element: any) => {
        if (element.quantityInStock != null && element.quantityInStock > 0){
          this.products.push(element);
        }
      })
    })
  }
}
