import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  // @ts-ignore
  transform(value: { label: string; description: string }[], search: string): { label: string; description: string }[] {
    if (value) {
      const regexp = new RegExp(search, 'i');
      const properties = Object.keys(value[0]);
      return [
        ...value.filter((item) => {
          // @ts-ignore
          return properties.some((property) => regexp.test(item[property]));
        }),
      ];
    }
  }
}
