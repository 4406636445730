import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalProductComponent} from "../../_helpers/modal";
import {environment} from "../../../environments/environment";
import {ProduitService} from "../../_services/produit.service";
import {CartService} from "../../_services/cart.service";
import {TokenStorageService} from "../../_services/token-storage.service";
import {Product} from "../../_models/product";


const S3 = `${environment.s3}`;

@Component({
  selector: ' app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {


  @Input('product') product: any = [];
  S3 = S3;
  isLoggin : boolean = false;
  displayLoading = false;

  constructor(
    private modalService: NgbModal,
    private cartService: CartService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggin = true;
    }
  }

  openModal() {
    let product = {productId: this.product.id};
    this.displayLoading = true;
    if(this.product.quantityInStock) {
      this.cartService.addProductToCart(product, this.tokenStorage.getToken(), 1)
      .subscribe({
        next: () => {
          const modalRef = this.modalService.open(ModalProductComponent, {size: 'lg'});
          modalRef.componentInstance.produit = {titre : this.product.label, image : this.product.thumbnails[0], prix: (this.product.price / 100)};
          this.displayLoading = false;
        },
        error: (err) => {
          const modalRef = this.modalService.open(ModalProductComponent, {size: 'lg'});
          modalRef.componentInstance.produit = {titre : this.product.label, image : this.product.thumbnails[0], prix: (this.product.price / 100), error: 'CART.ORDER.ERROR.MESSAGE'};
          this.displayLoading = false;
        }
      });
    } else {
      const modalRef = this.modalService.open(ModalProductComponent, {size: 'lg'});
      modalRef.componentInstance.produit = {titre : this.product.label, image : this.product.thumbnails[0], prix: (this.product.price / 100), error: 'CART.ORDER.ERROR.STOCK-UNAVAILABLE'};
      this.displayLoading = false;
    }
  }

  slugify = (...args: (string | number)[]): string => {
    const value = args.join(' ')

    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '')
      .replace(/\s+/g, '-')
  }
}
